<template>
  <oib-page style="background-color: var(--primary-color-sub);">
    <oib-nav-student title="Filter" :hide_bottom_on_mobile="true">
      <template v-slot:left>
        <oib-button-icon
        icon="arrow_back"
        style="font-size: 24px;"
        @click="hide_filter"
        />
      </template>
    </oib-nav-student>
    <div v-if="$mq == 'mobile'" class="submit" :class="$mq">
      <div class="wrapper" style="justify-content: center">
        <oib-button @click="apply_filter">Toepassen</oib-button>
      </div>
    </div>
    <oib-content padding_top="padding_top" style="background-color: var(--primary-color-sub)">

      <!-- Filter swiper -->
      <oib-swiper style="padding-left: 5%" :style="$mq == 'desktop' ? 'margin-top: -45px; margin-bottom: 5px' : ''">
        <div style="display: flex; flex-direction: row">
          <span class="material-icons-outlined filter-icon" @click="toggle_filter">tune</span>
          <oib-button-label
            class="clickable"
            :active="true"
            style="margin-right: 10px;"
            >
            <span>{{sort_mode}}</span>
          </oib-button-label>
          <oib-button-label
            class="clickable"
            :active="age_range_text"
            style="margin-right: 10px;"
            >
            <span>{{age_range_text ? age_range_text : 'Leeftijd'}}</span>
          </oib-button-label>
          <oib-button-label
            class="clickable"
            :active="release_year_range[0] != min_year || release_year_range[1] != max_year"
            style="margin-right: 10px;"
            >
            <span>{{release_year_range[0] != min_year || release_year_range[1] != max_year ? release_year_range_text : 'Datum uitgifte'}}</span>
          </oib-button-label>
          <oib-button-label
            class="clickable"
            :active="book_type_index_set.size > 0"
            style="margin-right: 40px;"
            >
            <span v-if="book_type_index_set.size ==  0">Boek type</span>
            <span v-if="book_type_index_set.size ==  1">{{book_type_list[[...book_type_index_set][0]]}}</span>
            <span v-if="book_type_index_set.size >  1">Boek type ({{book_type_index_set.size}})</span>
          </oib-button-label>
        </div>
      </oib-swiper>

      <!-- Sort form -->
      <div class="wrapper column">
        <div style="max-width: 1025px">
          <h2>Sorteren op</h2>
          <div style="margin-bottom: 0px">
            <div
            v-for="sort_mode_option in sort_modes"
            :key="sort_mode_option"
            class="sort-selector"
            @click="sort_mode = sort_mode_option"
            >
              <p
              style="font-size: 0.9em; margin: 0; width: 200px;"
              :style="sort_mode_option == sort_mode ? 'color: var(--highlight-color)' : 'color: white'"
              >{{sort_mode_option}}</p>
              <oib-button-icon
              v-if="sort_mode_option == sort_mode"
              icon="check"
              style="font-size: 24px; padding: 0; color: var(--highlight-color) "
              />
            </div>
          </div>
          <div class="row sort-section-header">
            <h2 class="h2">Leeftijd</h2>
            <p style="font-size: 0.8em">{{age_range_text}}</p>
          </div>
          <div class="row" style="justify-content: center">
            <vue-slider
            v-model="age_range"
            :height="8"
            dotSize="20"
            :min="0"
            :max="18"
            dotStyle="background-color: white; border-color: white;"
            style="padding: 12.5px 0px; width: 98%"
            />
          </div>
          <div class="row sort-section-header">
            <h2 class="h2">Datum uitgifte</h2>
            <p style="font-size: 0.8em">{{release_year_range_text}}</p>
          </div>
          <div class="row" style="justify-content: center">
            <vue-slider
            v-model="release_year_range"
            :height="8"
            dotSize="20"
            :min="min_year"
            :max="max_year"
            :data="year_list"
            style="padding: 12.5px 0px; width: 98%"
            />
          </div>
          <div class="row sort-section-header">
            <h2 class="h2">Boek type <span v-if="book_type_index_set.size > 0">({{book_type_index_set.size}})</span></h2>
          </div>
        </div>
      </div>
      <oib-swiper style="padding-left: 5%">
        <div style="display: flex; flex-direction: row;">
          <oib-button
            v-for="(book_type, index) in book_type_list"
            :key="book_type"
            :priority="book_type_index_set.has(index) ? 'p1' : 'p3'"
            style="margin-right: 10px; padding: 10px 20px;"
            @click="toggle_book_type(index)"
          >
          {{book_type}}
          </oib-button>
          <div style="width: 72px;"/>
        </div>
      </oib-swiper>
      <div class="wrapper">
        <oib-button
        v-if="$mq == 'desktop'"
        style="width: 200px; padding: 10px 30px; margin-top: 50px;"
        @click="apply_filter"
        >
        Toepassen
        </oib-button>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibButtonLabel from '../components/OibButtonLabel.vue'
import OibSwiper from '../components/oib_swiper.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import OibButton from '../components/OibButton.vue'

export default {
  name: "BookSearch",
  props: {
    init_sort_mode: {
      required: false,
      default: 'Meest relevant'
    },
    init_age_range: {
      required: false,
      default: [0, 18]
    },
    init_release_year_range: {
      required: false,
      default: [1970, 2023]
    },
    init_book_type_index_set: {
      required: false,
      default: new Set()
    },
    sort_modes: {
      required: false,
      default: [
        'Meest relevant',
        'Meest gelezen',
        'Minst gelezen',
        'Langst geleend',
        'Nieuw geleend',
        'Onlangs toegevoegd',
        'Nieuwste'
      ]
    }
  },
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibButtonIcon,
    OibSwiper,
    OibButtonLabel,
    VueSlider,
    OibButton
  },
  data() {
    return {
      query_obj: {},
      tag_name: null,
      classroom_id: null,
      user_id: null,
      user_id_recommendation: null,
      progress_state: null,
      is_favorite: null,
      age_known: null,
      age_range: [0, 18],
      min_year: 1970,
      max_year: 2023,
      release_year_range: [1970, 2023],
      year_list: [],
      row_count: null,
      column_count: null,
      search_query: '',
      book_tile_width: 160,
      search_limit: 0,
      query_running: false,
      padding: 0,
      grouping: null,
      select_set_copy_id: new Set(),
      book_type_list: [
        'Leesboek',
        'Informatief',
        'Prentenboek',
        'Aanwijsboek',
        'Stripboek',
        'Voorleesboek',
        'Dichtbundel',
        'Zoekboek',
        'Oriëntatie op lezen',
        'Samenleesboek',
        'Vakliteratuur'
      ],
      sort_mode: null,
      book_type_index_set: new Set()
    }
  },
  computed: {
    age_range_text() {
      if (this.age_known == 0) {
        return 'Onbekend'
      }

      if (this.age_range[0] == 0 && this.age_range[1] == 18) {
        return null
      }

      if (this.age_range[0] == this.age_range[1]) {
        return this.age_range[0] + ' jaar'
      } else {
        return this.age_range[0] + ' t/m ' + this.age_range[1] + ' jaar'
      }
    },
    release_year_range_text() {
      if (this.release_year_range[0] == this.release_year_range[1]) {
        return this.release_year_range[0]
      } else {
        return this.release_year_range[0] + ' t/m ' + this.release_year_range[1]
      }
    }
  },
  watch: {
    age_range() {
      if (this.age_range != [0, 18]) {
        this.age_known = 1
      }      
    }
  },
  mounted() {
    this.sort_mode = this.init_sort_mode
    this.age_range = this.init_age_range
    this.release_year_range = this.init_release_year_range
    this.book_type_index_set = this.init_book_type_index_set
  },
  methods: {
    hide_filter() {
      this.$emit("hide")
    },
    toggle_book_type(index) {
      if (this.book_type_index_set.has(index)) {
        this.book_type_index_set.delete(index)
      } else {
        this.book_type_index_set.add(index)
      }
    },
    apply_filter() {
      let query = this.gen_query()
      query['sort'] = this.sort_mode
      this.$emit("submit", query)

      // let query_parsed = parse_query(query)
      // if (query_parsed == '') {
      //   query_parsed = null
      // }
      
      // this.$router.replace({
      //   name: this.$route.name,
      //   params: {
      //     'query': query_parsed
      //   }
      // })
      // this.search()
      // this.show_filter = false
    },
    gen_query() {

      const book_type_list_server = [
        'LEESBOEK',
        'INFORMATIEF',
        'PRENTENBOEK',
        'AANWIJSBOEK',
        'STRIPBOEK',
        'VOORLEESBOEK',
        'DICHTBUNDEL', 
        'ZOEKBOEK',
        'ORIENTATIE_OP_LEZEN',
        'SAMENLEESBOEK',
        'VAKLITERATUUR'
      ]

      let query = {}
      // if (this.tag_name) {
      //   query['tag_name'] = this.tag_name
      //   query['min_tag_relevance'] = this.tag_tile_dict[this.tag_name].min_tag_relevance
      // }
      // if (this.classroom_id) {
      //   query['classroom_id'] = this.classroom_id
      // }
      // if (this.user_id) {
      //   query['user_id'] = this.user_id
      // }
      // if (this.user_id_recommendation) {
      //   query['user_id_recommendation'] = this.user_id_recommendation
      // }
      // if (this.progress_state) {
      //   query['progress_state'] = this.progress_state
      // }
      // if (this.is_favorite) {
      //   query['is_favorite'] = this.is_favorite
      // }
      // if (this.age_known != null) {
      //   query['age_known'] = this.age_known
      // }
      if (this.age_range[0] != 0) {
        query['min_age'] = this.age_range[0]
        query['age_known'] = 1
      }
      if (this.age_range[1] != 18) {
        query['max_age'] = this.age_range[1]
        query['age_known'] = 1
      }
      if (this.release_year_range[0] != this.min_year) {
        query['min_release_date'] = this.release_year_range[0] + '-01-01'
      }
      if (this.release_year_range[1] != this.max_year) {
        query['max_release_date'] = this.release_year_range[1] + '-01-01'
      }
      if (this.book_type_index_set.size > 0) {
        let book_type_list = []
        this.book_type_index_set.forEach(x => book_type_list.push(book_type_list_server[x]))
        query['book_type'] = book_type_list.join(',')
      }

      return query
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}
</style>
