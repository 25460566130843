<template>
  <oib-page style="z-index: 4">
    <oib-nav-student title="Groep filter kiezen"/>
    <oib-content>

      <div style="margin-top: 10px; margin-bottom: 100px;">
        <div v-if="$store.getters.get_location_selector_list.length == 0" class="wrapper">
          <p class="description">Je kunt geen groep kiezen omdat er nog geen groepen zijn toegevoegd. Lees ook: 
            <a href="https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding" target="_blank">
              Hoe koppel ik het leerlingvolgsysteeem?
            </a>
          </p>
        </div>
        <oib-list-item-button
          v-for="(selector, index) in $store.getters.get_location_selector_list"
          :key="selector.name"
          :header="selector.name"
          :text="selector.building_name"
          button_text="selecteren"
          @click="submit(index)"
        />
      </div>
    </oib-content>
  </oib-page>
</template>

<script>

import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibListItemButton from "../components/oib_list_item_button.vue"

export default {
  name: "LibrarySelector",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibListItemButton
  },
  methods: {
    submit(index) {
      this.$store.commit("set_location_selector_active", index)
      this.$router.back()
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
}

.swiper-slide { width: auto; }

.tags-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>
