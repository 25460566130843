<template>
  <oib-form-book-vote-audience 
  v-if="loaded"
  :scrape_data="scrape_data"
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormBookVoteAudience from "../components/oib_form_book_vote_audience.vue";

import { post_book_products_users_scrape } from "../store/api/book_scraper"
import { get_book_products_users_scrape_data } from "../store/api/book_scraper"
import { put_book_products_users_scrape_data } from "../store/api/book_scraper"

const LANGUAGE_SERVER = {
  0: 'DUTCH',
  1: 'ENGLISH',
  2: 'GERMAN',
  3: 'FRENCH',
  4: 'SPANISH',
  5: 'FRYSIAN'
}

const BOOK_TYPE_SERVER = {
    0: 'LEESBOEK',
    1: 'INFORMATIEF',
    2: 'PRENTENBOEK',
    3: 'STRIPBOEK',
    4: 'DICHTBUNDEL',
    5: 'VOORLEESBOEK',
    6: 'ORIENTATIE_OP_LEZEN',
    7: 'ZOEKBOEK',
    8: 'AANWIJSBOEK',
    9: 'SAMENLEESBOEK',
    10: 'VAKLITERATUUR',
}

export default {
  name: "BoekStemmenPaginas",
  props: ['user_id', 'isbn13'],
  components: {
    OibFormBookVoteAudience
  },
  data() {
    return {
      init_page_count: "",
      scrape_data: null,
      loaded: false
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const response = await get_book_products_users_scrape_data(jwt, this.isbn13, this.user_id)
    if (response.status == 200) {
      this.scrape_data = await response.json()
    } else {
      this.scrape_data = {
        'book_title': null,
        'book_type': null,
        'author_name': null,
        'series_title': null,
        'release_number': null,
        'language': null,        
        'dyslexia': null,
        'avi': null,
        'min_age': null,
        'max_age': null,
        'publisher_name': null,
        'binding_method': null,
        'page_count': null,
        'release_date': null,
        'cover': null
      }
    }

    this.loaded = true
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      // Vote on book
      const jwt = this.$store.getters.get_jwt
      await put_book_products_users_scrape_data(
          jwt,
          this.isbn13,
          this.user_id,
          this.scrape_data.book_title,
          BOOK_TYPE_SERVER[body.book_type],
          this.scrape_data.author_name,
          this.scrape_data.series_title,
          this.scrape_data.release_number,
          LANGUAGE_SERVER[body.book_language],
          body.dyslexia,
          body.avi,
          body.min_age,
          body.max_age,
          this.scrape_data.publisher_name,
          this.scrape_data.binding_method,
          this.scrape_data.page_count,
          this.scrape_data.release_date,
          null,
          [],
          this.scrape_data.cover
      )

      post_book_products_users_scrape(jwt, this.isbn13, this.user_id)

      this.$router.back();
    }
  }
};
</script>
