<template>
  <oib-page>
    <oib-nav-student title="Gebruiker verwijderen"/>
    <oib-content >
      <oib-menu-item
      :description="`Type 'Verwijderen' om ${user_name} te verwijderen.`"
      style="padding-top: 20px;"
      >
        <template v-slot:title>
          <oib-icon-label
          style="color: white;"
          icon="delete"
          title="Bevestigen"
          direction="row"
          :active="false"
          />
        </template>
      </oib-menu-item>
      <div class="wrapper column">
        <input type="text" placeholder="Verwijderen" v-model="confirmation"/>
        <oib-button
          shape="pill"
          style="margin-top: 50px;"
          :disabled="!is_valid_form"
          @click="submit"
          >Verwijderen</oib-button>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"
import OibButton from "../components/OibButton.vue"

import {delete_users} from "../store/api/user.js"
import {delete_users_accounts_external} from "../store/api/user.js"

export default {
  name: "GebruikerVerwijderen",
  props: ['user_id'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel,
    OibButton
  },
  data() {
    return {
      confirmation: "",
      user_name: "",
      error_msg: " ",
    }
  },
  computed: {
    is_valid_form() {
      return this.confirmation == 'Verwijderen'
    },
  },
  methods: {
    async submit() {
      // Delete library
      const jwt = this.$store.getters.get_jwt
      await delete_users(jwt, this.user_id)
      await delete_users_accounts_external(jwt, this.user_id)

      // Go back to teacher overview
      this.$router.back()
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  mounted() {
    const user = this.$store.getters.getUsers[this.user_id]
    this.user_name = user.user_name
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}
</style>