<template>
  <div>
    <book-search-filter
    v-if="show_filter"
    :init_sort_mode="init_sort_mode"
    :init_age_range="age_range"
    :init_release_year_range="release_year_range"
    :init_book_type_index_set="book_type_index_set"
    @submit="query => go_to_search_filter(query)"
    @hide="show_filter = false"
    />
    <oib-page v-if="!show_filter">
      <oib-nav-student title="Zoeken" filling="">
        <template v-slot:center>
          <h4>{{ select_set_copy_id.size }} geselecteerd</h4>
        </template>
        <template v-slot:left>
          <oib-button-icon
          icon="close"
          style="font-size: 24px;"
          @click="select_set_copy_id = new Set()"
          />
        </template>
        <template v-slot:right>
          <div class="row" style="min-width: 110px;">
            <oib-button-icon
            icon="drive_file_move"
            style="font-size: 24px;"
            @click="move_book_selection"
            />
            <oib-button-icon
            icon="delete"
            style="font-size: 24px;"
            @click="delete_book_selection"
            />
          </div>
        </template>
        <template v-slot:header><slot name="header">
          <oib-header-search
          v-if="select_set_copy_id.size == 0"
          :search_frequency_ms="0"
          placeholder="Boeken zoeken"
          @click_searchbar="go_to_search"
          >
            <template v-slot:search_collection>
              <oib-library-picker-button-inline v-if="filter_library"/>  
            </template>
            <template v-slot:on_empty_search>
              <oib-button-book-scan style="padding-right: 20px;" :style="$mq == 'desktop'? 'color: var(--primary-color)': 'color: var(--contrast-color)'" />
            </template>
          </oib-header-search>
        </slot></template>
      </oib-nav-student>
      <oib-content padding_top='padding_top'>
        <oib-swiper
        @click="toggle_filter"
        class="fade"
        style="padding-left: 5%"
        :style="`${$mq == 'desktop' ? 'margin-top: -45px; margin-bottom: 5px;' : ''} ${select_set_copy_id.size == 0 ? 'opacity: 1' : 'opacity: 0.1'}`"
        >
          <div style="display: flex; flex-direction: row">
            <span class="material-icons-outlined filter-icon">tune</span>
            <oib-button-label
              class="clickable"
              :active="true"
              style="margin-right: 10px;"
              >
              <span>{{sort_mode}}</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="age_range_text"
              style="margin-right: 10px;"
              >
              <span>{{age_range_text ? age_range_text : 'Leeftijd'}}</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="release_year_range[0] != min_year || release_year_range[1] != max_year"
              style="margin-right: 10px;"
              >
              <span>{{release_year_range[0] != min_year || release_year_range[1] != max_year ? release_year_range_text : 'Datum uitgifte'}}</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="book_type_index_set.size > 0"
              style="margin-right: 40px;"
              >
              <span v-if="book_type_index_set.size ==  0">Boek type</span>
              <span v-if="book_type_index_set.size ==  1">{{book_type_list[[...book_type_index_set][0]]}}</span>
              <span v-if="book_type_index_set.size >  1">Boek type ({{book_type_index_set.size}})</span>
            </oib-button-label>
          </div>
        </oib-swiper>
        <div
          class="wrapper row"
          style="align-items: center; margin-top: 0px; margin-bottom: 20px;"
          >
        </div>
        <oib-book-grid-date
        v-if="book_grid_date.includes(sort_mode)"
        :query="query_obj"
        :sub_text_key="sub_text_key"
        :show_checkbox="grouping == 'copies' ? select_set_copy_id.size == 0 ? 'on_hover' : 'always' : 'never'"
        @select="on_select"
        ref="book_grid"
        >
          <div
            class="row tag-tile"
            style="width: 130px; height: 80px; margin-bottom: 20px; border-radius: 4px; padding: 10px; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); margin-right: 1vw"
            :style="`background-color: gray`"
            @click="go_to_search_filter({'sort': 'Meest gelezen'})"
            >
              <h4 style="margin: 0; min-width: 70px; max-width: 70px">Bekijk alles</h4>
              <img :src="stack_of_books_src" alt="" class="drop-shadow" style="width: 60px; height: 60px; transform: translate(20px, 20px) rotate(25deg); background-color: white; border-radius: 2px; padding: 5px;" />
          </div>
          <oib-tag-tile
            v-for="tile in tag_tile_dict"
            :key="tile.name"
            :tag_id="1"
            :tag_name="tile.tag_name"
            :color="tile.color"            
          />
        </oib-book-grid-date>
        <oib-book-grid
        v-if="book_grid_default.includes(sort_mode)"
        :query="query_obj"
        :sub_text_key="sub_text_key"
        :show_checkbox="grouping == 'copies' ? select_set_copy_id.size == 0 ? 'on_hover' : 'always' : 'never'"
        @select="on_select"
        ref="book_grid"
        >
          <div
            class="row tag-tile"
            style="width: 130px; height: 80px; margin-bottom: 20px; border-radius: 4px; padding: 10px; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); margin-right: 1vw"
            :style="`background-color: gray`"
            @click="go_to_search_filter({'sort': 'Meest gelezen'})"
            >
              <h4 style="margin: 0; min-width: 70px; max-width: 70px">Bekijk alles</h4>
              <img :src="stack_of_books_src" alt="" class="drop-shadow" style="width: 60px; height: 60px; transform: translate(20px, 20px) rotate(25deg); background-color: white; border-radius: 2px; padding: 5px;" />
          </div>
          <oib-tag-tile
            v-for="tile in tag_tile_dict"
            :key="tile.name"
            :tag_id="1"
            :tag_name="tile.tag_name"
            :color="tile.color"            
          />
        </oib-book-grid>
      </oib-content>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibButtonBookScan from '../components/oib_button_book_scan.vue'
import OibButtonLabel from '../components/OibButtonLabel.vue'
import OibSwiper from '../components/oib_swiper.vue'
import OibLibraryPickerButtonInline from '../components/oib_library_picker_button_inline.vue'
import OibBookGrid from '../components/oib_book_grid.vue'
import OibBookGridDate from '../components/oib_book_grid_date.vue'
// import OibBookSearchList from '../components/oib_book_search_list.vue'
import OibTagTile from '../components/oib_tag_tile.vue'
import BookSearchFilter from '../components/book_search_filter.vue'

import { parse_query } from '../store/request'

export default {
  name: "BookSearch",
  props: {
    query: {
      required: false,
      default: null
    },
    filter_library: {
      type: Boolean
    },
    sort_modes: {
      type: Array,
      required: true
    }
  },
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibButtonIcon,
    OibHeaderSearch,
    OibButtonBookScan,
    OibSwiper,
    OibButtonLabel,
    OibLibraryPickerButtonInline,
    OibBookGrid,
    OibBookGridDate,
    // OibBookSearchList,
    OibTagTile,
    BookSearchFilter
  },
  data() {
    return {
      stack_of_books_src: require('@/assets/svg/stack_of_books.png'),
      book_grid_date: ["Langst geleend", "Nieuw geleend", "Onlangs toegevoegd"],
      book_grid_default: ["Meest relevant", "Meest gelezen", "Minst gelezen", "Nieuwste"],
      sub_text_key: 'author_name',
      query_obj: {},
      show_filter: false,
      tag_name: null,
      classroom_id: null,
      user_id: null,
      user_id_recommendation: null,
      progress_state: null,
      is_favorite: null,
      age_known: null,
      age_range: [0, 18],
      min_year: 1970,
      max_year: 2023,
      release_year_range: [1970, 2023],
      year_list: [],
      row_count: null,
      column_count: null,
      book_tile_width: 160,
      search_limit: 0,
      query_running: false,
      padding: 0,
      grouping: null,
      select_set_copy_id: new Set(),
      book_type_list: [
        'Leesboek',
        'Informatief',
        'Prentenboek',
        'Aanwijsboek',
        'Stripboek',
        'Voorleesboek',
        'Dichtbundel',
        'Zoekboek',
        'Oriëntatie op lezen',
        'Samenleesboek',
        'Vakliteratuur'
      ],
      sort_mode: null,
      book_type_index_set: new Set(),
      tag_tile_dict: {
        'magie en fantasie': {
          'name': 'Magie & fantasie',
          'img': require('@/assets/svg/magie.svg'),
          'color': '#0081C9',
          'tag_name': 'magie en fantasie',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'sprookjes': {
          'name': 'Sprookjes',
          'img': require('../assets/svg/crown.svg'),
          'color': '#F99F38',
          'tag_name': 'sprookjes',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'detective': {
          'name': 'Detective',
          'img': require('../assets/svg/detective.svg'),
          'color': '#567189',
          'tag_name': 'detective',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'science fiction': {
          'name': 'Science-fiction',
          'img': require('../assets/svg/science_fiction.svg'),
          'color': '#242F9B',
          'tag_name': 'science fiction',
          'min_tag_relevance': 0.1,
          'book_type': ['LEESBOEK','AANWIJSBOEK','PRENTENBOEK','STRIPBOEK','VOORLEESBOEK','DICHTBUNDEL','ZOEKBOEK']
        },
        'dieren': {
          'name': 'Dieren',
          'img': require('../assets/svg/tiger_face.svg'),
          'color': '#1F8A70',
          'tag_name': 'dieren',
          'min_tag_relevance': 0.41,
          'book_type': null
        },
        'griezelig': {
          'name': 'Griezel',
          'img': require('../assets/svg/ghost.svg'),
          'color': '#6F38C5',
          'tag_name': 'griezelig',
          'min_tag_relevance': 0.35,
          'book_type': null
        },
        'sport': {
          'name': 'Sport',
          'img': require('../assets/svg/sport.svg'),
          'color': '#A62349',
          'tag_name': 'sport',
          'min_tag_relevance': 0.15,
          'book_type': null
        },
        'oorlog': {
          'name': 'Oorlog',
          'img': require('../assets/svg/war.svg'),
          'color': '#8B7E74',
          'tag_name': 'oorlog',
          'min_tag_relevance': 0.1,
          'book_type': null
        },
        'geschiedenis': {
          'name': 'Geschiedenis',
          'img': require('../assets/svg/greek_temple.svg'),
          'color': '#D0B8A8',
          'tag_name': 'geschiedenis',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'aardrijkskunde': {
          'name': 'Aardrijkskunde',
          'img': require('../assets/svg/earth.svg'),
          'color': '#5BC0F8',
          'tag_name': 'aardrijkskunde',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'kunst en cultuur': {
          'name': 'Kunst & cultuur',
          'img': require('../assets/svg/art.svg'),
          'color': '#FD8A8A',
          'tag_name': 'kunst en cultuur',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'avontuur': {
          'name': 'Avontuur',
          'img': require('../assets/svg/adventure.svg'),
          'color': '#d49d5b',
          'tag_name': 'avontuur',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        'superhelden': {
          'name': 'Superhelden',
          'img': require('../assets/svg/superhero.svg'),
          'color': '#CD0404',
          'tag_name': 'superhelden',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        // 'vakantie': {
        //   'name': 'Vakantie',
        //   'img': require('../assets/svg/holiday.svg'),
        //   'color': '#FFC93C',
        //   'tag_name': 'vakantie',
        //   'min_tag_relevance': 0.2
        // },
        // 'lente': {
        //   'name': 'Lente',
        //   'img': require('../assets/svg/sapling.svg'),
        //   'color': '#82CD47',
        //   'tag_name': 'lente',
        //   'min_tag_relevance': 0.2
        // },
        // 'zomer': {
        //   'name': 'Zomer',
        //   'img': require('../assets/svg/sun.svg'),
        //   'color': '#5BC0F8',
        //   'tag_name': 'zomer',
        //   'min_tag_relevance': 0.2
        // },
        'liefde en relatiesn': {
          'name': 'Liefde',
          'img': require('../assets/svg/liefde_en_relaties.svg'),
          'color': '#FF8B13',
          'tag_name': 'liefde en relatiesn',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        // 'winter': {
        //   'name': 'Winter',
        //   'img': require('../assets/svg/snowman.svg'),
        //   'color': '#E1D7C6',
        //   'tag_name': 'winter',
        //   'min_tag_relevance': 0.4
        // },
        'feest': {
          'name': 'Feest',
          'img': require('../assets/svg/party.svg'),
          'color': '#E64848',
          'tag_name': 'feest',
          'min_tag_relevance': 0.1,
          'book_type': null
        },
        'wetenschap en techniek': {
          'name': 'Wetenschap',
          'img': require('../assets/svg/science.svg'),
          'color': '#00FFD1',
          'tag_name': 'wetenschap en techniek',
          'min_tag_relevance': 0.2,
          'book_type': ['INFORMATIEF'],
        },
        'grappig': {
          'name': 'Humor',
          'img': require('../assets/svg/laughing_face.svg'),
          'color': '#FF74B1',
          'tag_name': 'grappig',
          'min_tag_relevance': 0.4,
          'book_type': null
        },
        'school': {
          'name': 'School verhaal',
          'img': require('../assets/svg/backpack.svg'),
          'color': '#0081B4',
          'tag_name': 'school',
          'min_tag_relevance': 0.4,
          'book_type': null
        },
        // 'lichaam en gezondheid': {
        //   'name': 'Gezondheid',
        //   'img': require('../assets/svg/lungs.svg'),
        //   'color': '#F48484',
        //   'tag_name': 'lichaam en gezondheid',
        //   'min_tag_relevance': 0.1
        // },
        'eten en drinken': {
          'name': 'Eten & drinken',
          'img': require('../assets/svg/food.svg'),
          'color': '#1F8A70',
          'tag_name': 'eten en drinken',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        'krijgers': {
          'name': 'Krijgers verhaal',
          'img': require('../assets/svg/swords.svg'),
          'color': '#8a123e',
          'tag_name': 'krijgers',
          'min_tag_relevance': 0.31,
          'book_type': null
        },
        'misdaad': {
          'name': 'Misdaad',
          'img': require('../assets/svg/policeman.svg'),
          'color': '#126c8a',
          'tag_name': 'misdaad',
          'min_tag_relevance': 0.3,
          'book_type': null
        },
        'geloof en religie': {
          'name': 'Religie',
          'img': require('../assets/svg/candle.svg'),
          'color': '#2faad4',
          'tag_name': 'geloof en religie',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'nederland': {
          'name': 'Nederland',
          'img': require('../assets/svg/dutch_flag.svg'),
          'color': '#FFA500',
          'tag_name': 'nederland',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        // 'beroepen': {
        //   'name': 'Beroepen',
        //   'img': require('../assets/svg/teacher.svg'),
        //   'color': '#3e73e6',
        //   'tag_name': 'beroepen',
        //   'min_tag_relevance': 0.5,
        //   'book_type': null
        // }
      }
    }
  },
  created() {
    this.load_query_from_url()
  },
  async mounted() {

    // Do not run if user is not defined - User did refresh or logout
    if (!this.$store.getters.getUser) {
      return
    }

    // Start search
    // Since some mobile browsers resize on mounted, add timeout to wait for resize event
    // Without this timeout there will be a mismatch between the query_limit and the search_limit
    this.search();
  },
  async updated() {
    this.load_query_from_url()
    this.search();
  },
  computed: {
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    selector_name() {
      if (!this.filter_library) {
        return 'Boeken die nog niet op school staan'
      }

      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
    age_range_text() {
      if (this.age_known == 0) {
        return 'Onbekend'
      }

      if (this.age_range[0] == 0 && this.age_range[1] == 18) {
        return null
      }

      if (this.age_range[0] == this.age_range[1]) {
        return this.age_range[0] + ' jaar'
      } else {
        return this.age_range[0] + ' t/m ' + this.age_range[1] + ' jaar'
      }
    },
    release_year_range_text() {
      if (this.release_year_range[0] == this.release_year_range[1]) {
        return this.release_year_range[0]
      } else {
        return this.release_year_range[0] + ' t/m ' + this.release_year_range[1]
      }
    }
  },
  watch: {
    active_library_selector() {
      this.search()
    },
    age_range() {
      this.age_known = 1
    }
  },
  methods: {
    load_query_from_url() {

      // Reset
      this.tag_name = null

      // Compute year list
      const year_min = new Date(this.min_year + '-01-01').getFullYear()
      const year_max = this.max_year

      let i = 0
      while (year_min + i <= year_max) {
        const year = (year_min + i).toString()
        this.year_list.push(year)
        i++
      }

      this.sort_mode = this.sort_modes[0]

      const book_type_list_dict = {
        'LEESBOEK': 0,
        'INFORMATIEF': 1,
        'PRENTENBOEK': 2,
        'AANWIJSBOEK': 3,
        'STRIPBOEK': 4,
        'VOORLEESBOEK': 5,
        'DICHTBUNDEL': 6, 
        'ZOEKBOEK': 7,
        'ORIENTATIE_OP_LEZEN': 8,
        'SAMENLEESBOEK': 9,
        'VAKLITERATUUR': 10
      }

      const filter_list = this.query.split('&')
      filter_list.forEach(filter => {
        const values = filter.split('=')
        if (values[0] == 'sort') {
          this.sort_mode = values[1]
        } else if (values[0] == 'tag_name') {
          this.tag_name = values[1] 

          const book_type_list = this.tag_tile_dict[this.tag_name].book_type
          this.book_type_index_set = new Set()
          if (book_type_list != null) {
            book_type_list.forEach(book_type => this.book_type_index_set.add(book_type_list_dict[book_type]))
          }
          
        } else if (values[0] == 'classroom_id') {
          this.classroom_id = values[1]
        } else if (values[0] == 'user_id') {
          this.user_id = values[1]
        } else if (values[0] == 'user_id_recommendation') {
          this.user_id_recommendation = values[1]
        } else if (values[0] == 'progress_state') {
          this.progress_state = values[1]
        } else if (values[0] == 'is_favorite') {
          this.is_favorite = values[1]
        } else if (values[0] == 'min_release_date') {
          this.release_year_range[0] = values[1].slice(0, 4)
        } else if (values[0] == 'max_release_date') {
          this.release_year_range[1] = values[1].slice(0, 4)
        } else if (values[0] == 'age_known') {
          this.age_known = values[1]
        } else if (values[0] == 'min_age') {
          this.age_range[0] = values[1]
        } else if (values[0] == 'max_age') {
          this.age_range[1] = values[1]
        } else if (values[0] == 'book_type') {
          const book_type_list = values[1].split(',')
          book_type_list.forEach(book_type => {
            this.book_type_index_set.add(book_type_list_dict[book_type])
          })
        }
      })

      this.init_sort_mode = this.sort_mode
    },
    toggle_filter() {
      if (this.select_set_copy_id.size == 0) {
        this.show_filter = !this.show_filter
      }      
    },
    gen_query() {

      const book_type_list_server = [
        'LEESBOEK',
        'INFORMATIEF',
        'PRENTENBOEK',
        'AANWIJSBOEK',
        'STRIPBOEK',
        'VOORLEESBOEK',
        'DICHTBUNDEL', 
        'ZOEKBOEK',
        'ORIENTATIE_OP_LEZEN',
        'SAMENLEESBOEK',
        'VAKLITERATUUR'
      ]

      let query = {}
      if (this.tag_name) {
        query['tag_name'] = this.tag_name
        query['min_tag_relevance'] = this.tag_tile_dict[this.tag_name].min_tag_relevance
      }
      if (this.classroom_id) {
        query['classroom_id'] = this.classroom_id
      }
      if (this.user_id) {
        query['user_id'] = this.user_id
      }
      if (this.user_id_recommendation) {
        query['user_id_recommendation'] = this.user_id_recommendation
        query['force_variety'] = 1
        query['can_borrow'] = 1
      }
      if (this.progress_state) {
        query['progress_state'] = this.progress_state
      }
      if (this.is_favorite) {
        query['is_favorite'] = this.is_favorite
      }
      if (this.age_known != null) {
        query['age_known'] = this.age_known
      }
      if (this.age_range[0] != 0) {
        query['min_age'] = this.age_range[0]
        query['age_known'] = 1
      }
      if (this.age_range[1] != 18) {
        query['max_age'] = this.age_range[1]
        query['age_known'] = 1
      }
      if (this.release_year_range[0] != this.min_year) {
        query['min_release_date'] = this.release_year_range[0] + '-01-01'
      }
      if (this.release_year_range[1] != this.max_year) {
        query['max_release_date'] = this.release_year_range[1] + '-01-01'
      }
      if (this.book_type_index_set.size > 0) {
        let book_type_list = []
        this.book_type_index_set.forEach(x => book_type_list.push(book_type_list_server[x]))
        query['book_type'] = book_type_list.join(',')
      }

      return query
    },
    async search() {

      // Build query
      let query = this.gen_query()

      // Skip search if tag tiles are displayed
      if (this.query === 'null') {
        this.query_obj = {}
        return
      }

      // Set default settings
      let grouping = 'editions'
      let read_count_var = this.classroom_id ? 'classroom_read_count' : 'borrow_count_sum'

      query['select'] = 'isbn13,book_edition_id,book_title,author_name'

      let sub_text_key = 'author_name'

      // Apply represents_edition filter if searching for title, series or author
      if (this.sort_mode == 'Meest relevant') {
        if (this.user_id_recommendation == null) {
          query['sort'] = 'read_count,DESC'
        }
        query['select'] = 'isbn13,book_edition_id,book_title,author_name'
      } else if (this.sort_mode == 'Meest gelezen') {
        query['sort'] = `${read_count_var},DESC`
        query['select'] = 'isbn13,book_edition_id,book_title,author_name,release_date'
      } else if (this.sort_mode == 'Minst gelezen') {
        query['sort'] = `${read_count_var},ASC`
        query['select'] = 'isbn13,book_edition_id,book_title,author_name,release_date'
      } else if (this.sort_mode == 'Langst geleend') {
        grouping = 'copies'
        query['sort'] = 'borrowed_datetime,ASC'
        query['select'] = 'copy_id,isbn13,book_edition_id,book_title,borrowed_datetime'
        query['in_use'] = 1
        sub_text_key = 'borrowed_datetime'
      } else if (this.sort_mode == 'Nieuw geleend') {
        grouping = 'copies'
        query['sort'] = 'borrowed_datetime,DESC'
        query['select'] = 'copy_id,isbn13,book_edition_id,book_title,borrowed_datetime'
        query['in_use'] = 1
        sub_text_key = 'borrowed_datetime'
      } else if (this.sort_mode == 'Onlangs toegevoegd') {
        grouping = 'copies'
        query['sort'] = 'addition_datetime,DESC'
        query['select'] = 'copy_id,isbn13,book_edition_id,book_title,addition_datetime'
        sub_text_key = 'addition_datetime'
      } else if (this.sort_mode == 'Nieuwste') {
        query['sort'] = 'release_date,DESC'
        query['select'] = 'isbn13,book_edition_id,book_title,author_name'
        sub_text_key = 'release_date'
      } else if (this.sort_mode == 'Datum uitgelezen') {
        grouping = 'products'
        query['sort'] = 'progress_datetime,DESC'
        query['select'] = 'isbn13,book_edition_id,book_title,author_name,progress_datetime'
        sub_text_key = 'progress_datetime'
      }

      // Apply classroom select
      if (this.classroom_id) {
        query['select'] += ',classroom_read_count'
      }

      // Apply library filters
      const library_filters = ['Meest relevant', 'Meest gelezen', 'Minst gelezen', 'Nieuwste']
      if (this.filter_library && !this.user_id) {
        const library_selector = this.$store.getters.get_active_library_selector
        if (library_selector != null) { 
          if (this.user_id_recommendation) {
            const library_id_access = Array.from(this.$store.getters.get_user_library_dict[this.user_id_recommendation])
            query['library_id'] = library_id_access.join(',')
          } else {
            query['library_id'] = this.$store.getters.get_library_selector_list[library_selector].query['library_id']
          }          

          if (library_filters.includes(this.sort_mode)) {
            query['select'] += ',copies_in_use,copies_total,borrow_count_sum,min_age,max_age'
            query['organisation_id'] = this.$store.getters.get_organisation_id_active
          }
        }
      }

      this.grouping = grouping
      this.sub_text_key = sub_text_key
      this.query_obj = {
        'grouping': grouping,
        'query': query
      }
    },
    pick_library() {
      this.$router.push({
        name: 'BibliotheekKiezenGroep',
        params: {
          'user_id': this.user_id,
          'library_route': 'back'
        }
      });
    },
    go_to_search() {
      if (window.history.state.back === '/bibliotheek/zoeken') {
        this.$router.back()
      } else {
        this.$router.replace({
          name: "BibliotheekZoeken"
        });
      }
    },
    go_to_search_filter(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.show_filter = false

      this.$router.replace({
        name: "BibliotheekZoekenFilter",
        params: {
          'query': query_parsed
        }
      })
      this.search()
    },
    on_select(copy_id) {
      if (this.select_set_copy_id.has(copy_id)) {
        this.select_set_copy_id.delete(copy_id)
      } else {
        this.select_set_copy_id.add(copy_id)
      }
    },
    move_book_selection() {
      this.$router.push({
        name: 'BibliotheekBoekVerplaatsen',
        params: {
          'user_id': this.user_id
        },
        query:{
          'copy_id': Array.from(this.select_set_copy_id).join(',')
        }
      });
      this.$refs.book_grid.reset_search()
    },
    delete_book_selection() {
      this.$router.push({
        name: 'BibliotheekBoekVerwijderen',
        query:{
          'copy_id': Array.from(this.select_set_copy_id).join(',')
        }
      });
      this.$refs.book_grid.reset_search()
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}
</style>
