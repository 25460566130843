<template>
  <oib-page style="z-index: 4">
    <oib-nav-student title="Bieb kiezen">
      <template v-slot:right>
        <oib-button-icon icon="refresh" ref="refresh" @click="reload_libraries" style="font-size: 24px;"/>
      </template>
    </oib-nav-student>
    <oib-content>
      <div style="margin-top: 10px; margin-bottom: 100px;">
        <div v-if="library_id_list.length == 0" class="wrapper">
          <p class="description">Je kunt geen bieb kiezen omdat er nog geen bieb is toegevoegd. Lees ook: 
            <a href="https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#bibliotheken_toevoegen" target="_blank">
              Hoe voeg ik een nieuwe bieb toe?
            </a>
          </p>
        </div>
        <oib-list-item-button
          v-for="library_id in library_id_list"
          :key="library_id"
          :image_url="get_image_url(library_id)"
          :header="library_dict[library_id].library_name"
          :text="library_dict[library_id].location_name"
          button_text="selecteren"
          @click="submit(library_id)"
        />
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibListItemButton from "../components/oib_list_item_button.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue";

import {get_bucket} from "../store/bucket.js"
import { reload_libraries } from "../store/library";

export default {
  name: "BibliotheekKiezen",
  props: {
    user_id: {
      required: true
    },
    enable_option_all: {
      type: Boolean,
      default: false
    },
    physical_access_only: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibListItemButton,
    OibButtonIcon
  },
  data() {
    return {
      library_id_list: [],
      library_dict: {}
    }
  },
  created() {
    this.init_library_list()
  },
  methods: {
    async reload_libraries() {
      await reload_libraries()
      this.init_library_list()
      this.$refs.refresh.$el.classList.add('rotate')
      setTimeout(this.stop_refresh_anim, 200)
    },
    stop_refresh_anim() {
      this.$refs.refresh.$el.classList.remove('rotate')
    },
    init_library_list() {
      // Ignore on refresh
      const user = this.$store.getters.getUser
      if(!this.$store.getters.getUser) {
        return
      }

      const role_list = user['role']

      this.library_dict = this.get_library_dict()

      // Add all option to library_id_list
      this.library_id_list = []
      if (this.enable_option_all) {
        this.library_id_list.push(0)
      }

      if (this.filter_roles(['SYSTEM_ADMIN', 'READING_CONSULTANT', 'BOOKSHOP_OWNER', 'LIBRARIAN', 'LIBRARY_ASSISTENT'], role_list)) {
        const library_id_list_all = Object.keys(this.library_dict)
        library_id_list_all.forEach(x => {
            if (x != '0') {
              this.library_id_list.push(x)
            }
        })
      }
      else if (!this.filter_roles(['LIBRARIAN', 'LIBRARY_ASSISTENT'], role_list) && this.physical_access_only) {
        
        // Get library_id_list to which the user has access   
        const access_dict = this.$store.getters.get_user_access_dict[this.user_id]
        if (!access_dict) {
          return
        }

        // Get all libraries in own classrooms
        const classroom_id_set = access_dict.classroom_id_set
        const library_list = this.$store.getters.get_library_list
        library_list.forEach((library) => {
          const classroom_id = library['classroom_id']
          if (classroom_id != null && classroom_id_set.has(classroom_id)) {
            this.library_id_list.push(library['library_id'])
          }
        })
      } else {
        // Get all libraries that this user has access to
        const library_id_set = this.$store.getters.get_user_library_dict[this.user_id]
        if (library_id_set && library_id_set.size > 0) {
          library_id_set.forEach(library_id => this.library_id_list.push(library_id))
        }
      }

      this.library_id_list.sort((a, b) => this.library_dict[a].library_name.localeCompare(this.library_dict[b].library_name))
    },
    filter_roles(valid_roles, user_roles) {
      for (let i = 0; i < valid_roles.length; i++) {
        for (let k = 0; k < user_roles.length; k++) {
          if (valid_roles[i] == user_roles[k]) {
            return true
          }
        }
      }

      return false;
    },
    get_image_url(library_id) {
      return `${get_bucket('media')}/libraries/s/${library_id}.webp`
    },
    get_library_dict() {

      const library_dict = this.$store.getters.get_library_dict
      const library_id_list = Object.keys(library_dict)
      
      // Set default home
      this.library_dict = {}
      this.library_dict[0] = {
        'library_id': 0,
        'library_name': 'Alles',
        'location_name': 'Thuis'
      }

      for (let i = 0; i < library_id_list.length; i++) {
        const library_id = library_id_list[i]
        this.library_dict[library_id] = {
          'library_id': library_id,
          'library_name': library_dict[library_id]['library_name'],
          'location_name': library_dict[library_id]['location_name'],
        }
      }

      return this.library_dict
    },
    async submit(library_id) {
      this.$store.commit('set_active_library_id', library_id)
      this.$emit('input', library_id)
    }
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}


.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.rotate {
  animation: rotation .2s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>