<template>
  <library-selector-picker @submit="$router.back()"/>
</template>

<script>

import LibrarySelectorPicker from "../components/library_selector_picker.vue";

export default {
  name: "BibliotheekGroepKiezen",
  components: {
    LibrarySelectorPicker
  }
};
</script>
