import store from './index'
import router from '../router/index'

import { request_post_plain } from "./request.js"
import { request_put } from "./request.js"
import { load_library_user_access } from "./library.js"
import { load_book_progress_states } from "./book_progress.js"
import { get_users } from './api/user.js'
import { post_schools_partners_authenticate } from "./api/user.js"
import { set_cookie } from "../cookie.js"

export function parse_name(user) {
  if (!user) {
    return ""
  }

  if (user['insertion_name'] == null) {
    return user['nick_name'] + ' ' + user['last_name']
  }
  return user['nick_name'] + ' ' + user['insertion_name'] + ' ' + user['last_name']
}

export async function put_users_accounts_internal_token(jwt, user_id, id_token) {
  /* Add a QR-code authentication method to a user.

  :param int user_id: the id of the user to which this id_token should be connected.
  :param int id_token: the identification token that will authenticate this user in the future.
  */

  const request_path = `/users/${user_id}/accounts/internal/token`
  const body = {'id_token': id_token}

  return await request_put(jwt, request_path, body, undefined)
}

export async function post_accounts_internal_token_authenticate(id_token) {
  /* Login with an identification token and return the user.

  :param int id_token: the identification token that will authenticate a user.
  */

  const request_path = `/accounts/internal/token/authenticate`
  const body = {'id_token': id_token}

  return await request_post_plain(request_path, body)
}

export async function accounts_external_google_authenticate(id_token) {
  /* Login with an identification token provided by Google and return the user.

  :param int id_token: the identification token that will authenticate a user.
  */

  const request_path = '/accounts/external/GOOGLE/authenticate'
  const body = {'id_token': id_token}

  return await request_post_plain(request_path, body)
}

export async function accounts_external_microsoft_authenticate(id_token) {
  /* Login with an identification token provided by Microsoft and return the user.

  :param int id_token: the identification token that will authenticate a user.
  */

  const request_path = '/accounts/external/MICROSOFT/authenticate'
  const body = {'id_token': id_token}

  return await request_post_plain(request_path, body)
}

export async function load_student(user_id) {

    // Get user
    await load_user(user_id)
    
    // Get borrowed books
    // Get book progress of books where state=READING
    const jwt = store.getters.getJwt

    await Promise.all([
      store.dispatch("load_books_borrowed", {
        'jwt': jwt,
        'user_id': user_id
      }),
      load_book_progress_states(user_id, 1),
      load_book_progress_states(user_id, 2),
      store.dispatch("get_group_users", {
        'jwt': jwt,
        'query': {
          'user_id': user_id,
          'organisation_id': store.getters.get_organisation_id_active,
          'schoolyear': store.getters.get_schoolyear
        }    
      })
    ])

    // Compute access of user
    store.commit("compute_user_access_dict", user_id)

    // Load library access
    // if (!(user_id in store.getters.get_user_library_dict)) {
    load_library_user_access(user_id)
    // }
}

export async function load_user(user_id) {
  let user = store.getters.getUsers[user_id];
  if (!user) {
    const jwt = store.getters.get_jwt
    user = await get_users(jwt, user_id)
     store.commit("add_user", user)
  }
  return user
}

function filter_roles(valid_roles, user_roles) {
  for (let i = 0; i < valid_roles.length; i++) {
    for (let k = 0; k < user_roles.length; k++) {
      if (valid_roles[i] == user_roles[k]) {
        return true
      }
    }
  }

  return false;
}

function get_resource_access_scope() {
  const user = store.getters.getUser
  if (!user) {
    return null
  }

  const user_role_list = user['role']

  // Librarian and supervisor have access to all buildings and classrooms within school
  if (filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'SCHOOL_ADMIN', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], user_role_list)) {
    return {
      'organisation_id_set': new Set([store.getters.get_organisation_id_active]),
      'building_id_set': new Set(Object.keys(store.getters.get_building_dict)),
      'classroom_id_set': new Set(Object.keys(store.getters.get_classroom_dict))
    }
  }

  // Teachers only have access to their own classroom
  else if (filter_roles(['STUDENT', 'TEACHER'], user_role_list)) {
    const user_id = user['user_id']
    const user_access =  store.getters.get_user_access_dict[user_id]
    return {
      'classroom_id_set': user_access.classroom_id_set
    }
  }
  else {
    return null
  }
}

export function load_location_selectors() {
      const user = store.getters.getUser
      if (!user) {
        return
      }

      const roles = user['role']
      const resource_access_scope = get_resource_access_scope()

      const building_dict = store.getters.get_building_dict
      const classroom_dict = store.getters.get_classroom_dict_active      

      const organisation_id_set = resource_access_scope['organisation_id_set']
      const building_id_set = resource_access_scope['building_id_set']
      const classroom_id_set = resource_access_scope['classroom_id_set']

      let location_selector_list = []

      if (filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], roles)) {
          // Organisation
          for (const organisation_id of organisation_id_set) {
            location_selector_list.push({
                'name': 'School',
                'building_name': 'School',
                'query': {'organisation_id': organisation_id}
            })
          }

          // Buildings
          if (building_id_set.size > 1) {
            for (const building_id of building_id_set) {
              const building_name = building_dict[building_id]['building_name']
              location_selector_list.push({
                  'name': building_name,
                  'building_name': building_name,
                  'query': {'building_id': building_id}
              })
            }
          }
      }

      // Classrooms
      let classroom_list = []
      for (const classroom_id of classroom_id_set) {

        // Filter inactive in the current schoolyear
        if (!(classroom_id in classroom_dict)) {
          continue
        }

        const classroom = classroom_dict[classroom_id]
        const classroom_name = classroom['classroom_name']
        const building_name = building_dict[classroom.building_id]['building_name']
        classroom_list.push({
            'name': classroom_name,
            'building_name': building_name,
            'query': {'classroom_id': classroom_id}
        })
      }

      if (filter_roles(['TEACHER'], roles) && !filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], roles)) {
        let classroom_id_list = []
        classroom_list.forEach(x => classroom_id_list.push(x.query['classroom_id']))

        location_selector_list.push({
            'name': 'School',
            'building_name': 'School',
            'query': {'classroom_id': classroom_id_list.join(',')}
        })
      }

      classroom_list.sort((a, b) => a.name.localeCompare(b.name))
      classroom_list.forEach(location => {
        location_selector_list.push(location)
      })
      store.commit("set_location_selector_list", location_selector_list)
}

export async function attempt_school_partner_login() {
  // Adds school resources to external access scope of partner
  // The jwt of the system admin won't have to be updated

  const jwt = store.getters.get_jwt
  const school_id_partner = store.getters.get_school_id_active
  if (school_id_partner && store.getters.getUser['role'].includes('LIBRARY_PARTNER')) {
    const response = await post_schools_partners_authenticate(jwt, school_id_partner)
    const json = await response.json()
    store.commit("set_jwt", json['jwt'])
  }
}

function signOut() {
  set_cookie("ACCOUNT_TYPE", '', 365)
  set_cookie("ACCOUNT_EMAIL", '', 365)

  try {
    const auth2 = window.gapi.auth2.getAuthInstance();
    
    auth2.signOut().then(function () {
      auth2.disconnect();
    });

  } catch {
    // attempt again within 100 ms
    //setTimeout(() => signOut(), 200);
  }

  // Redirect to login screen
  router.push({
    name: "Login",
  });
}

export function logout() {
  const user = store.getters.getUser

  // Logout if unauthorized
  if (!user) {
    signOut();
  }

  // Logout of school session
  else if ((user['role'].includes('SYSTEM_ADMIN') || user['role'].includes('LIBRARY_PARTNER')) && store.getters.get_school_id_active) {
    store.commit('set_organisation_id_active', store.getters.get_organisation_id)
    store.commit('set_school_id_active', null)
    store.dispatch("reset_libraries")
    store.commit("reset_users")
    store.commit("set_user", store.getters.getUser)
    store.commit("reset_lvs")
    store.commit("reset_tasks")
    router.push({
      name: "Laden"
    });
  } else {
    // Reset user State
    store.commit("resetCurrentUser");
    store.commit("reset_users")
    store.commit("reset_progress", user['user_id']);
    store.commit("reset_progress_state", user['user_id']);
    store.dispatch("reset_libraries")
    store.commit("reset_lvs")
    store.commit("reset_tasks")
    signOut()
  }
}
