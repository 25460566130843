<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Boek toevoegen" @submit="submit">
    <div>
      <oib-form-header header="Identificatie"/>
      <oib-input-text
      label="isbn13"
      placeholder="9789025864842"
      :required="true"
      :error_msg="isbn13_error"
       :init_value="isbn13"
      @input="set_isbn13($event.target.value)"
      />
    </div>

    <div>
      <oib-form-header header="Vormgeving"/>
      <oib-input-image
      label="Cover"
      placeholder="schoolbieb"
      :required="true"
      :init_value="cover_url"
      @input="x => set_image(x)"
      />
    </div>

    <!--- story -->
    <div>
      <oib-form-header header="Verhaal - unieke kenmerken"/>

      <oib-input-dropdown-search
      label="Titel"
      placeholder="Dolfje Weerwolfje"
      search_key="book_title"
      :required="true"      
      :search="search_book_title"
      :init_value="book_title_init"
      @oib_input="item => input_book_title(item)"
      @oib_click="item => set_book_story(item)"
      >
        <template v-slot:default="{item}">
          <img class="avatar" :src="getCoverUrl(item.isbn13)" />
          <div>
            <h3 class="h4" style="color: var(--primary-color)">{{item.book_title}}</h3>
            <p class="subh4" style="color: var(--primary-color)">
              {{ item.isbn13 }}
            </p>
          </div>
        </template>
      </oib-input-dropdown-search>

      <oib-input-dropdown-search
      label="Auteur"
      placeholder="Paul van Loon"
      search_key="author_name"
      :required="true"      
      :search="search_author"
      :init_value="author_name_init"
      @oib_input="item => input_author_name(item)"
      @oib_click="item => click_author_name(item.author_name)"
      >
        <template v-slot:default="{item}">
          <h3 class="h4">{{item.author_name}}</h3>
        </template>
      </oib-input-dropdown-search>

      <oib-input-dropdown-search
      label="Serie"
      placeholder="Dolfje Weerwolfje"
      search_key="series_title"
      :required="false"      
      :search="search_series"
      :init_value="series_title_init"
      @oib_input="series_title => input_series_title(series_title)"
      @oib_click="item => click_series_title(item.series_title)"
      >
        <template v-slot:default="{item}">
          <h3 class="h4">{{item.series_title}}</h3>
        </template>
      </oib-input-dropdown-search>

      <oib-input-text
      label="Serie nummer"
      placeholder="1"
      :required="false"
      :error_msg="isbn13_error"
       :init_value="release_number"
      @input="set_release_number($event.target.value)"
      />
    </div>

    <div>
      <oib-form-header header="Verhaal - overige kenmerken"/>
      <oib-input-multi-select
        label="Boek categorie"
        description="In welke categorie valt dit boek?"
        :allow_multi_select="false"
        :required="true"
        :options="book_type_selector_list"
        :keys_init="get_book_type_selector_index()"
        @select="x => set_book_type(book_type_selector_list[parseInt(x[0])])"
      />
    </div>

    <div>
      <oib-form-header header="Editie"/>
      <oib-input-multi-select
        label="Taal"
        description="In welke taal is deze editie uitgegeven?"
        :allow_multi_select="false"
        :required="true"
        :options="language_selector_list"
        :keys_init="get_language_selector_index()"
        @select="x => set_language(language_selector_list[parseInt(x[0])])"
      />
      <oib-input-multi-select
        label="Dyslexie"
        description="Is deze editie een dyslexie uitgave?"
        :allow_multi_select="false"
        :required="true"
        :options="dyslexia_selector_list"
        :keys_init="get_dyslexia_selector_index()"
        @select="x => set_dyslexia(dyslexia_selector_list[parseInt(x[0])])"
      />
    </div>

    <div>
      <oib-form-header header="Doelgroep"/>
      <oib-input-multi-select
        label="Avi"
        description="Is dit boek geschreven voor een specifiek avi niveau?"
        :allow_multi_select="false"
        :required="true"
        :options="avi_selector_list"
        :keys_init="get_avi_selector_index()"
        @select="x => set_avi(avi_selector_list[parseInt(x[0])])"
      />
      <oib-input-text
      label="Minimale leeftijd"
      placeholder="1"
      :required="false"
      :error_msg="isbn13_error"
       :init_value="min_age"
      @input="set_min_age($event.target.value)"
      />
      <oib-input-text
      label="Maximale leeftijd"
      placeholder="12"
      :required="false"
      :error_msg="isbn13_error"
       :init_value="max_age"
      @input="set_max_age($event.target.value)"
      />
    </div>

    <div>
      <oib-form-header header="Product"/>
      <!-- publisher -->
      <oib-input-dropdown-search
      label="Uitgeverij"
      placeholder="Noordhoff"
      search_key="publisher_name"
      :required="true"
      :search="search_publishers"
      :init_value="publisher_name_init"
      @oib_input="item => input_publisher_name(item)"
      @oib_click="item => click_publisher_name(item.publisher_name)"
      >
        <template v-slot:default="{item}">
          <h3 class="h4">{{item.publisher_name}}</h3>
        </template>
      </oib-input-dropdown-search>

      <oib-input-text
      label="Paginanummers"
      placeholder="69420"
      :required="true"
      :init_value="page_count"
      @input="set_page_count($event.target.value)"
      />
      <oib-input-multi-select
        label="Bindwijze"
        description="Hoe is dit boek gebonden?"
        :allow_multi_select="false"
        :required="true"
        :tile_width="140"
        :options="binding_method_selector_list"
        :keys_init="get_binding_method_selector_index()"
        @select="x => set_binding_method(binding_method_selector_list[parseInt(x[0])])"
      />
      <oib-input-text
      label="Publicatie datum"
      placeholder="1970-01-01"
      :required="false"
       :init_value="release_date"
      @input="set_release_date($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibInputImage from "../components/oib_form/oib_input_image.vue"
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"
import OibInputDropdownSearch from "../components/oib_form/oib_input_dropdown_search.vue"


import {get_authors} from "../store/api/library.js"
import {get_series} from "../store/api/library.js"
import {get_publishers} from "../store/api/library.js"
import {get_books} from "../store/api/library.js"
import {get_book_cover} from "../store/api/library"

export default {
  name: "OibFormBookAdd",
  props: ["init_isbn13"],
  components: {
    OibForm,
    OibFormHeader,
    OibInputText,
    OibInputImage,
    OibInputMultiSelect,
    OibInputDropdownSearch
  },
  data() {
    return {
      form_step_validation_list: [false, true, false, true, true, true, false],
      isbn13: "",
      isbn13_error: null,
      book_title: "",
      book_title_init: "",
      book_type: null,
      book_type_selector_list: ['Leesboek', 'Informatief', 'Prentenboek', 'Stripboek', 'Dichtbundel', 'Voorleesboek', 'Oriëntatie op lezen', 'Zoekboek', 'Aanwijsboek', 'Samenleesboek', 'Vakliteratuur'],
      language_selector_list: ['Nederlands', 'Engels', 'Duits', 'Frans', "Spaans", 'Fries', 'Russisch'],
      dyslexia_selector_list: ['Ja', 'Nee'],
      avi_selector_list: ['START', 'M3', 'E3', 'M4', 'E4', 'M5', 'E5', 'M6', 'E6', 'M7', 'E7', 'PLUS'],
      binding_method_selector_list: ['Paperback', 'Hardcover'],
      dyslexia: null,
      binding_method: null,
      page_count: "",
      cover_file: null,
      search_result: [],
      book_title_search_result: [],
      author_name: "",
      author_name_init: "",
      author_search_result: [],
      series_title: "",
      series_title_init: "",
      series_search_result: [],
      release_number: "",
      publisher_search_result: [],
      publisher_name: "",
      publisher_name_init: "",
      language: "",
      avi: null,
      min_age: "",
      max_age: "",
      release_date: "",
      cover_url: null,
      language_dict: {
        0: 'Nederlands',
        1: 'Engels',
        2: 'Duits',
        3: 'Frans',
        4: 'Spaans',
        5: 'Fries',
        6: 'Russisch'
      },
    }
  },
  async created() {
    if (this.init_isbn13 !== 'null') {
      await this.set_isbn13(this.init_isbn13)
    }
  },
  methods: {
    set_book_story(value) {
      if (!value) {
        return
      }
      this.book_title = value['book_title']
      this.book_title_init = value['book_title']

      if ('author_name' in value) {
        this.author_name = value['author_name']
        this.author_name_init = value['author_name']
      }
      if (value['series_title']) {
        this.series_title = value['series_title']
        this.series_title_init = value['series_title']
        this.release_number = ""
      }
      if (value['release_number']) {
        this.release_number = value['release_number']
      }

      this.val_step_2()
    },
    getCoverUrl(isbn13) {
      if (isbn13) {
        return get_book_cover(isbn13, 's')
      }
      else {
        return "https://us.123rf.com/450wm/teploleta/teploleta1801/teploleta180100105/94540385-seamless-vector-pattern-with-doodle-questions-marks-on-a-blackboard-background.jpg?ver=6";
      }
    },
    in_search_result(var_name, var_value) {
      let book_title_list = []
      for (let i = 0; i < this.search_result.length; i++) {
        book_title_list.push(this.search_result[i][var_name])
      }
      return !book_title_list.includes(var_value)
    },
    get_book_type_selector_index() {
      if (this.book_type == 'Leesboek') {
        return new Set([0])
      } else if (this.book_type == 'Informatief') {
        return new Set([1])
      } else if (this.book_type == 'Prentenboek') {
        return new Set([2])
      } else if (this.book_type == 'Stripboek') {
       return new Set([3])
      } else if (this.book_type == 'Dichtbundel') {
        return new Set([4])
      } else if (this.book_type == 'Voorleesboek') {
        return new Set([5])
      } else if (this.book_type == 'Oriëntatie op lezen') {
        return new Set([6])
      } else if (this.book_type == 'Zoekboek') {
        return new Set([7])
      } else if (this.book_type == 'Aanwijsboek') {
        return new Set([8])
      } else if (this.book_type == 'Samenleesboek') {
        return new Set([9])
      } else if (this.book_type == 'Vakliteratuur') {
        return new Set([10])
      }
      return null
    },
    get_language_selector_index() {
      if (this.language == 'Nederlands') {
        return new Set([0])
      } else if (this.language == 'Engels') {
        return new Set([1])
      } else if (this.language == 'Duits') {
        return new Set([2])
      } else if (this.language == 'Frans') {
       return new Set([3])
      } else if (this.language == 'Spaans') {
        return new Set([4])
      } else if (this.language == 'Fries') {
        return new Set([5])
      } else if (this.language == 'Russisch') {
        return new Set([6])
      }
      return null
    },
    get_dyslexia_selector_index() {
      const index = this.dyslexia ? 0 : 1
      return new Set([index])
    },
    get_avi_selector_index() {
      if (this.avi == 'START') {
        return new Set([0])
      } else if (this.avi == 'M3') {
        return new Set([1])
      } else if (this.avi == 'E3 ') {
        return new Set([2])
      } else if (this.avi == 'M4') {
       return new Set([3])
      } else if (this.avi == 'E4') {
        return new Set([4])
      } else if (this.avi == 'M5') {
        return new Set([5])
      } else if (this.avi == 'E5') {
        return new Set([6])
      } else if (this.avi == 'M6') {
        return new Set([7])
      } else if (this.avi == 'M7') {
        return new Set([8])
      } else if (this.avi == 'E7') {
        return new Set([9])
      } else if (this.avi == 'PLUS') {
        return new Set([10])
      }
      
      return null
    },
    get_binding_method_selector_index() {
      if (this.binding_method == 'Paperback') {
        return new Set([0])
      } else if (this.binding_method == 'Hardcover') {
        return new Set([1])
      }
      return null      
    },
    async set_isbn13(value) {
      this.isbn13 = value

      // Set image
      if (this.isbn13.length == 13) {
        if (!this.cover_file) {
        this.cover_url = get_book_cover(this.isbn13, 'l')
        }

        // Check if this is a new book, if not init values
        const jwt = this.$store.getters.getJwt
        const query = {
          'isbn13': this.isbn13,
          'select': 'isbn13,book_title,author_name,series_title,release_number,book_type,publisher_name,page_count,release_date,dyslexia,book_language,binding_method,avi,min_age,max_age'
        }

        const book_type_dict = {
            'LEESBOEK': 0,
            'INFORMATIEF': 1,
            'PRENTENBOEK': 2,
            'STRIPBOEK': 3,
            'DICHTBUNDEL': 4,
            'VOORLEESBOEK': 5,
            'ORIENTATIE_OP_LEZEN': 6,
            'ZOEKBOEK': 7,
            'AANWIJSBOEK': 8,
            'SAMENLEESBOEK': 9,
            'VAKLITERATUUR': 10
        }

        const language_dict = {
            'DUTCH': 0,
            'ENGLISH': 1,
            'GERMAN': 2,
            'FRENCH': 3,
            'SPANISH': 4,
            'FRYSIAN': 5
        }

        const json = await get_books(jwt, 'products', query)
        if (json['books'].length == 1) {
          const book = json['books'][0]
          const book_type_int = book['book_type'] ? book_type_dict[book['book_type']] : null
          const language_int = language_dict[book['book_language']]
          this.set_book_story(book)
          this.set_book_type(this.book_type_selector_list[book_type_int]),
          this.set_language(this.language_dict[language_int])
          this.set_dyslexia(book['dyslexia'] ? 'Ja' : 'Nee')
          this.set_avi(book['avi'])
          this.set_min_age(book['min_age'])
          this.set_max_age(book['max_age'])
          this.click_publisher_name(book['publisher_name'])
          this.set_page_count(book['page_count'])
          this.set_binding_method(this.binding_method_selector_list[book['binding_method']])
          this.set_release_date(book['release_date'])
        }        
      }

      this.val_step_1()
    },
    set_image(value) {
      this.cover_file = value
      this.val_step_1()
    },
    input_book_title(value) {
      this.book_title = value
      this.val_step_2()
    },
    input_author_name(value) {
      this.author_name = value
      this.val_step_2()
    },
    click_author_name(value) {
      this.author_name_init = value
      this.input_author_name(value)
    },
    input_series_title(value) {
      this.series_title = value
      this.val_step_2()
    },
    click_series_title(value) {
      this.series_title_init = value
      this.input_series_title(value)
    },
    set_release_number(value) {
      this.release_number = value
      this.val_step_2()
    },
    set_book_type(value) {
      this.book_type = value
      this.val_step_3()
    },
    set_language(language) {
      this.language = language
      this.val_step_4()
    },
    set_dyslexia(value) {
      this.dyslexia = value == 'Ja'
      this.val_step_4()
    },
    set_avi(avi) {
      this.avi = avi
      this.val_step_5()
    },
    set_min_age(min_age) {
      this.min_age = min_age
      this.val_step_5()
    },
    set_max_age(max_age) {
      this.max_age = max_age
      this.val_step_5()
    },
    input_publisher_name(value) {
      this.publisher_name = value
      this.val_step_6()
    },
    click_publisher_name(value) {
      this.publisher_name_init = value
      this.input_publisher_name(value)
    },
    set_page_count(value) {
      this.page_count = value
      this.val_step_6()
    },
    set_binding_method(value) {
      this.binding_method = value
      this.val_step_6()
    },
    set_release_date(value) {
      this.release_date = value
      this.val_step_6()
    },
    async search_book_title(search_query) {

      // Run search query
      const jwt = this.$store.getters.getJwt
      const query = {
        'search': search_query,
        'select': 'isbn13,book_edition_id,book_title,author_name,series_title,release_number',
        'page': 0,
        'limit': 6
      }
      const json = await get_books(jwt, 'editions', query, undefined)
      this.book_title_search_result = json['books']
      this.book_title = search_query
      return this.book_title_search_result
    },
    async search_author(search_query) {

      // Run search query
      const jwt = this.$store.getters.getJwt
      const query = {
        'search': search_query,
        'page': 0,
        'limit': 10
      }
      const json = await get_authors(jwt, query)
      return json['author_list']
    },
    async search_series(search_query) {

      // Run search query
      const jwt = this.$store.getters.getJwt
      const query = {
        'search': search_query,
        'page': 0,
        'limit': 10
      }
      const json = await get_series(jwt, query)
      return json['series_list']
    },
    async search_publishers(search_query) {

      // Run search query
      const jwt = this.$store.getters.getJwt
      const query = {
        'search': search_query,
        'page': 0,
        'limit': 10
      }
      const json = await get_publishers(jwt, query)
      return json['publisher_list']
    },
    async val_step_1() {

      // Were all required inputs filled?
      const data_entered = this.isbn13 != ""
      const isbn13_valid = this.isbn13.length == 13 && !isNaN(this.isbn13)

      // Set validation
      this.form_step_validation_list[0] = data_entered && isbn13_valid
    },
    val_step_2() {
      const book_title_valid = !(this.book_title === 'null' || this.book_title === 'undefined' || this.book_title.length < 3)
      const author_name_valid = !(this.author_name === 'null' || this.author_name === 'undefined' || this.author_name.length < 3)
      const series_valid = !(this.release_number && !this.series_title)

      this.form_step_validation_list[2] = book_title_valid && author_name_valid && series_valid
    },
    val_step_3() {
      this.form_step_validation_list[3] = this.book_type_selector_list.includes(this.book_type)
    },
    val_step_4() {
      this.form_step_validation_list[4] = this.language_selector_list.includes(this.language)
    },
    val_step_5() {
      let valid_min_age = true
      if (this.min_age) {
        valid_min_age = !isNaN(this.min_age) && parseInt(this.min_age) < 18
      }

      let valid_max_age = true
      if (this.max_age) {
        valid_max_age = this.min_age && !isNaN(this.max_age) && parseInt(this.max_age) < 18
      }
      this.form_step_validation_list[5] = valid_min_age && valid_max_age
    },
    val_step_6() {
      
      const dateformat_regex = /^\d{4}-\d{2}-\d{2}$/;
      const publisher_name_valid = this.publisher_name.length > 1
      const page_count_valid = !isNaN(this.page_count) && parseInt(this.page_count) < 2000
      const binding_method_valid = this.binding_method_selector_list.includes(this.binding_method)
      const release_date_valid = this.release_date.length > 0 && this.release_date.match(dateformat_regex)
      this.form_step_validation_list[6] = publisher_name_valid && page_count_valid && binding_method_valid && release_date_valid
    },
    async submit() {

      this.$emit("submit", {
        'isbn13': this.isbn13,
        'cover': this.cover_file,
        'book_title': this.book_title,
        'author_name': this.author_name,
        'series_title': this.series_title ? this.series_title : null,
        'release_number': this.release_number ? parseInt(this.release_number) : null,
        'book_type': parseInt([...this.get_book_type_selector_index()][0]),
        'book_language': [...this.get_language_selector_index()][0],
        'dyslexia': this.dyslexia,
        'avi': this.avi,
        'min_age': this.min_age ? parseInt(this.min_age) : null,
        'max_age': this.max_age ? parseInt(this.max_age) : null,
        'publisher_name': this.publisher_name,
        'page_count':  this.page_count ? parseInt(this.page_count) : null,
        'binding_method': [...this.get_binding_method_selector_index()][0],
        'release_date': this.release_date
      })
    }
  }
};
</script>

<style scoped>

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}
</style>