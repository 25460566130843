import { request_post } from "../request.js"
import { request_put } from "../request.js"
import { request_get } from "../request.js"
import { request_get_json } from "../request.js"
import { request_options } from "../request.js"
import { parse_query } from "../request.js"

export async function post_book_products_users_scrape_resource(jwt, isbn13, user_id, formdata) {
  /* Upload a resource to the custom scraper resource directory.
  It returns the url of the created resource.

  :param int isbn13: the isbn13 of the book product of the cover.
  :param object formdata: the resource.
  :return object response: a json object with an url.
  */

  const request_path = `/book-products/${isbn13}/users/${user_id}/scrape/resource`

  return await request_post(jwt, request_path, formdata)
}

export async function put_book_products_users_scrape_data(jwt, isbn13, user_id, book_title, book_type, author_name, series_title, release_number, book_language, dyslexia, avi, min_age, max_age, publisher_name, binding_method, page_count, release_date, description, tags, cover) {
  /* Upload a resource to the custom scraper resource directory.
  It returns the url of the created resource.

  :param int isbn13: the isbn13 of the book product of the cover.
  :param object formdata: the resource.
  :return object response: a json object with an url.
  */

  const request_path = `/book-products/${isbn13}/users/${user_id}/scrape/data`

  return await request_put(jwt, request_path, {
    'book_title': book_title,
    'book_type': book_type,
    'author_name': author_name,
    'series_title': series_title,
    'release_number': release_number,
    'book_language': book_language,
    'dyslexia': dyslexia,
    'avi': avi,
    'min_age': min_age,
    'max_age': max_age,
    'publisher_name': publisher_name,
    'binding_method': binding_method,
    'page_count': page_count,
    'release_date': release_date,
    'description': description,
    'cover': cover,
    'tags': tags,
  }, undefined)
}

export async function get_book_products_users_scrape_data(jwt, isbn13, user_id) {
  /* Query the publishers collection in the book_ext service
  
  :param str jwt: the json web token
  :param str isbn13: the isbn13 of the book
  :param int user_id: the unique id of the user that added the custom data.
  */

  const request_path = `/book-products/${isbn13}/users/${user_id}/scrape/data`
  return await request_get(jwt, request_path, undefined)
}

export async function options_book_products_users_scrape_data(jwt) {
  /* Get the options for this API
  
  :param str jwt: the json web token
  */

  const request_path = `/book-products/users/scrape/data`
  return await request_options(jwt, request_path, undefined)
}

export async function get_book_products_scrape_metadata_query(jwt, query) {
  /* Query the scrape metadata
  
  :param str jwt: the json web token

  :optional
  :param str isbn13: the isbn13 of the book
  */

  const query_string = parse_query(query)
  const request_path = `/book-products/scrape/metadata?${query_string}`
  return await request_get_json(jwt, request_path, undefined)
}

export async function post_book_products_users_scrape(jwt, isbn13, user_id) {
    /*
    Add an isbn13 to the priority queue of the scraper.

    :param int isbn13: the isbn13 to add to the priority queue.
    */
    const request_path = `/book-products/${isbn13}/users/${user_id}/scrape`
    return await request_post(jwt, request_path, {'force_download': 1, 'queue': 'PRIORITY'})
}
