import { request_post } from "../request.js"
import { request_put } from "../request.js"
import { request_delete } from "../request.js"
import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

/*
PROGRESS_STATE ENUM

READING = 1
READ = 2
QUIT = 3
*/

export async function post_users_book_products_progress(jwt, user_id, isbn13, pages_read, pages_total, progress_state, datetime_logged) {
  /*
  Add progress of a user in a book.

  :param int user_id: the unique id of the user.
  :param int isbn13: the unique isbn13 of the book product.
  :param int pages_read: the amount of the pages the user read in the book product.
  :param int pages_total: the total amount of pages in the book product.
  :param int progress_state: the current state of the user in the book.
  */

  const request_path = `/users/book-products/progress`
  const body = {
    'user_id': user_id,
    'isbn13': isbn13,
    'pages_read': pages_read,
    'pages_total': pages_total,
    'progress_state': progress_state,
    'datetime_logged': datetime_logged
  }

  const response = await request_post(jwt, request_path, body)
  return response
}


export async function put_users_book_products_progress(jwt, progress_id, user_id, isbn13, pages_read, pages_total, progress_state, datetime_logged) {
  /*
  Update a progress entry of a user in a book.

  :param int progress_id: the unique id of the progress entry.
  :param int user_id: the unique id of the user.
  :param int isbn13: the unique isbn13 of the book product.
  :param int pages_read: the amount of the pages the user read in the book product.
  :param int pages_total: the total amount of pages in the book product.
  :param int progress_state: the current state of the user in the book.
  */

  const request_path = `/users/book-products/progress/${progress_id}`
  const body = {
    'user_id': user_id,
    'isbn13': isbn13,
    'pages_read': pages_read,
    'pages_total': pages_total,
    'progress_state': progress_state,
    'datetime_logged': datetime_logged
  }

  return await request_put(jwt, request_path, body)
}

export async function get_users_book_products_favorites(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/book-products/favorites?${query_string}`, undefined)
}

export async function put_users_book_products_favorites(jwt, user_id, isbn13) {
  /*
  Add a book product as favorite of a user.

  :param int user_id: the unique id of the user.
  :param int isbn13: the unique isbn13 of the book product.
  */

  const request_path = `/users/${user_id}/book-products/${isbn13}/favorites`

  return await request_put(jwt, request_path, {})
}

export async function delete_users_book_products_favorites(jwt, user_id, isbn13) {
  /*
  Delete a book product as favorite from a user.

  :param int user_id: the unique id of the user.
  :param int isbn13: the unique isbn13 of the book product.
  */

  const request_path = `/users/${user_id}/book-products/${isbn13}/favorites`

  return await request_delete(jwt, request_path, {})
}
