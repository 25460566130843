<template>
  <oib-form-book-vote-tags 
  v-if="loaded"
  :isbn13="isbn13"
  :scrape_data="scrape_data"  
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormBookVoteTags from "../components/oib_form_book_vote_tags.vue";

import { post_book_products_users_scrape } from "../store/api/book_scraper"
import { get_book_products_users_scrape_data } from "../store/api/book_scraper"
import { put_book_products_users_scrape_data } from "../store/api/book_scraper"

export default {
  name: "BoekStemmenTrefwoorden",
  props: ['user_id', 'isbn13'],
  components: {
    OibFormBookVoteTags
  },
  data() {
    return {
      init_page_count: "",
      scrape_data: null,
      loaded: false
    }
  },
  async mounted() {
    const jwt = this.$store.getters.get_jwt
    const response = await get_book_products_users_scrape_data(jwt, this.isbn13, this.user_id)
    if (response.status == 200) {
      this.scrape_data = await response.json()
    } else {
      this.scrape_data = {
        'book_title': null,
        'book_type': null,
        'author_name': null,
        'series_title': null,
        'release_number': null,
        'book_language': null,        
        'dyslexia': null,
        'avi': null,
        'min_age': null,
        'max_age': null,
        'publisher_name': null,
        'binding_method': null,
        'page_count': null,
        'release_date': null,
        'cover': null,
        'tags': []
      }
    }

    this.loaded = true
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      // Vote on book
      const jwt = this.$store.getters.get_jwt
      await put_book_products_users_scrape_data(
          jwt,
          this.isbn13,
          this.user_id,
          this.scrape_data.book_title,
          this.scrape_data.book_type,
          this.scrape_data.author_name,
          this.scrape_data.series_title,
          this.scrape_data.release_number,
          this.scrape_data.book_language,
          this.scrape_data.dyslexia,
          this.scrape_data.avi,
          this.scrape_data.min_age,
          this.scrape_data.max_age,
          this.scrape_data.publisher_name,
          this.scrape_data.binding_method,
          this.scrape_data.page_count,
          this.scrape_data.release_date,
          null,
          body.tags,
          this.scrape_data.cover
      )

      post_book_products_users_scrape(jwt, this.isbn13, this.user_id)

      this.$router.back();
    }
  }
};
</script>
