<template>
  <div>
    <oib-header title="Koppel QR-code" style="z-index: 100" :show_back="true"/>
    <div class="fullscreen" ref="wrapper">
      <barcode-reader-advanced @decode="onDecode" format="qr_code"/>
      <div v-if="validationSuccess" class="validation-success">
        Koppeling voltooid
      </div>

      <div v-if="validationFailure" class="validation-failure">
        QR-code is ongeldig
      </div>

      <div v-if="validationPending && user" style="z-index: 5; position: fixed; display: flex; justify-content: center; align-items: center; width: 100%;">
        <div class="wrapper column" style="background-color: var(--primary-color-sub); padding: 30px; border-radius: 10px; max-width: 300px;">
          <oib-section-info
          img_url=""
          icon="warning"
          icon_family="icons"
          header="Boekenlegger is al gekoppeld!"
          style="color: white"
          >
            <p style="color: white; font-size: 0.9em">Deze boekenlegger is al gekoppeld aan {{parse_name(user)}}. Weet je zeker dat je deze boekenlegger wilt koppelen aan {{$store.getters.getUsers[user_id].user_name}}?</p>
          </oib-section-info>
          <div class="row">
            <oib-button
              style="width: 100px; padding: 10px 30px; margin-right: 20px;"
              @click="user = null; decoding = false"
              >Nee
            </oib-button>
            <oib-button
              style="width: 100px; padding: 10px 30px;"
              priority="p2"
              @click="connect_id_token(id_token)"
              >Ja
            </oib-button>
          </div>
        </div>
      </div>

      <div v-if="validationPending && !user" class="validation-pending">
        QR-code koppelen ...
      </div>
    </div>
  </div>
</template>

<script>
import BarcodeReaderAdvanced from "../components/barcode_reader_advanced3.vue";
import OibHeader from "../components/OibHeader.vue"
import OibButton from '../components/OibButton.vue'
import OibSectionInfo from '../components/oib_section_info.vue'

import { put_users_accounts_internal_token } from '../store/user.js'
import { get_users_query } from '../store/api/user.js'
import { parse_name } from "../store/user.js"

export default {
  props: ["user_id"],
  components: { 
    OibHeader,
    BarcodeReaderAdvanced,
    OibButton,
    OibSectionInfo
  },
  data () {
    return {
      isValid: undefined,
      decoding: false,
      user: null,
      id_token: null
    }
  },
  computed: {
    validationPending () {
      return this.isValid === undefined && this.decoding === true
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
    // Import
    parse_name,

    // Local
    async get_user_by_token(id_token) {
      const jwt = this.$store.getters.getJwt
      const response = await get_users_query(jwt, {'id_token': id_token})
      if (response.status != 200) {
        return null
      }

      const json = await response.json()
      const user_list = json['user_list']
      if (user_list.length == 1) {
        return user_list[0]
      }
      return null
    },
    async connect_id_token(id_token) {
      // Connect id_token to user
      const jwt = this.$store.getters.getJwt
      const response = await put_users_accounts_internal_token(jwt, this.user_id, id_token)
      
      // Display server response context
      this.isValid = response.status == 204;
      await this.timeout(1500)

      let user = this.$store.getters.getUser
      let role_list = user['role']
      if (role_list.includes('STUDENT')) {
        this.$router.back()
      } else {
        user = this.$store.getters.getUsers[this.user_id]
        role_list = user['role']
        if (role_list.includes('STUDENT')) {
          this.$router.push({
            name: "GebruikersLeerlingen",
          });
        } else {
          this.$router.go(-2)
        }
      }
    },
    async onDecode (result) {

      this.id_token = result.barcode
      this.decoding = true

      this.user = await this.get_user_by_token(this.id_token)
      if (this.user && this.user.user_id != this.user_id) {
        return
      }

      this.connect_id_token(this.id_token)
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: var(--highlight-color);
}
.validation-failure {
  color: red;
}

.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
</style>