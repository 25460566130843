<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Bieb inladen" @submit="submit">
    <div>
      <oib-form-header header="Bestand uploaden"/>
      <oib-input-xlsx
      label="Schoolbieb Excelbestand"
      :required="true"
      :error_msg="error_msg"
      @oib_input="on_xlsx_input"
      />
      <!-- <div v-if="worksheet" style="margin-top: -10px;">
        <oib-icon-label
          icon="check"
          icon_style="color: var(--highlight-color)"
          :title="`Rijen: ${worksheet.length}`"
          direction="row"
          :active="false"
        />
        <oib-icon-label
          icon="check"
          icon_style="color: var(--highlight-color)"
          :title="`labels: ${labels.size}`"
          direction="row"
          :active="false"
        />
      </div> -->
    </div>
    <div>
      <oib-form-header header="Labels kiezen"/>
      <oib-input-multi-select-descriptive
        description="Welke labels wil je aan de boeken koppelen?"
        :allow_multi_select="false"
        :required="true"
        :options="label_options"
        :sort_alphabetically="false"
        :error_msg="null"
        @select="x => set_label_strategy(x[0])"
      />

      <div style="margin-top: 0">
        <!-- <p v-if="unknown_isbn13_set.size > 0" style="color: var(--negative-color)">
          {{ unknown_isbn13_set.size }} ISBN-nummers zijn onbekend en worden online opgezocht. Probeer het later opnieuw.
        </p> -->
        <p v-if="error_msg_option" style="color: var(--negative-color); font-size: 0.8em;">
          {{ error_msg_option }}
        </p>
        <p v-if="label_option_selected == 'NBD labels' && unknown_count_nbd > 0" style="color: white; font-size: 0.9em;">
          {{unknown_count_nbd}} Bestelnummers onbekend!
        </p>
        <p  v-if="label_option_selected == 'NBD labels' && unknown_count_nbd > 0" style="font-size: 0.8em;">
          Onze servers gaan nu op zoek naar juiste labels. Dit duurt ongeveer {{ parseInt(Math.max(2, unknown_count_nbd * 10 / 60)) }} minuten.
        </p>
        <p  v-if="label_option_selected == 'NBD labels' && unknown_count_nbd > 0" style="font-size: 0.8em;">
          Bij onbekende labels raden we aan om een "Twijfel" bibliotheek toe te voegen. Achteraf kun je de boeken handmatig naar de juiste bibliotheek verplaatsen.
        </p>
      </div>
    </div>
    <div>
      <oib-form-header header="Labels koppelen"/>
      <div v-for="label in labels" :key="label">
        <oib-input-dropdown-search
        :label="label"
        :required="true"
        :search="search_library_name"
        :search_delay="0"
        :search_frequency_ms="0"
        :init_value="label in label_dict ? label_dict[label] : null"
        search_key="library_name"
        placeholder=""
        :allow_custom_input="false"
        :error_msg="label_dict[label] ? library_name_list.includes(label_dict[label]) ? '' : 'Biebnaam onbekend' : ''"
        
        @oib_input="library_name => set_library_name(label, library_name)"
        @oib_click="item => set_library_name(label, item.library_name)"
        >
          <template v-slot:default="{item}">
            <h3 class="h4">{{item.library_name}}</h3>
          </template>
        </oib-input-dropdown-search>
      </div>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputXlsx from "../components/oib_form/oib_input_xlsx.vue"
import OibInputDropdownSearch from './oib_form/oib_input_dropdown_search.vue';
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import { go } from "fuzzysort"
import { get_books } from "../store/api/library.js"
import { post_book_products_users_scrape } from "../store/api/book_scraper"
import { is_valid_isbn } from "../store/isbn.js"

function toChunks(array, chunkSize) {
  const chunkList = []
  for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunkList.push(chunk)
  }
  return chunkList
}

export default {
  name: "OibFormLibrary",
  components: {
    OibForm,
    OibFormHeader,
    OibInputXlsx,
    OibInputDropdownSearch,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false, false, false],
      library_name_list: [],
      worksheet: null,
      labels: new Set(),
      label_dict: {},
      error_msg: null,
      error_msg_option: null,
      label_option_selected: null,
      label_options: [
        {tag: 'Eigen labels', details: 'Koppel eigen labels aan de boeken.'},
        {tag: 'OIB labels', details: 'Koppel automatisch OIB-labels aan de boeken.'},
        {tag: 'NBD labels', details: 'Koppel automatisch NBD-labels aan de boeken.'}
      ],
      unknown_count_nbd: 0,
      unknown_label_str: 'ONBEKEND'
    }
  },
  created() {
      const library_list = this.$store.getters.get_library_list
      this.library_name_list = []
      library_list.forEach(x => this.library_name_list.push(x['library_name']))
  },
  mounted() {
    this.val_step_1()
  },
  methods: {
    on_xlsx_input(worksheet) {

      // Quit if worksheet was empty
      if (!worksheet || worksheet.length == 0) {
        return
      }

      this.worksheet = worksheet
      this.init_columns()
      this.val_step_1()
      this.val_step_2()
    },
    init_columns() {

      // Parse columns
      for (let i = 0; i < this.worksheet.length; i++) {
        this.worksheet[i] = this.keys_to_lowercase(this.worksheet[i])
      }

      // Get available columns
      const column_keys = Object.keys(this.worksheet[0])

      let has_isbn_column = column_keys.includes('isbn')
      let has_nbd_column = column_keys.includes('bestelnummer')

      if (!has_nbd_column && !has_isbn_column) {
        this.error_msg = "Het bestand mist een 'isbn' of 'bestelnummer' kolom."
        return
      }

      if (has_isbn_column) {
        const isbn13InvalidSet = new Set()
        for (let i = 0; i < this.worksheet.length; i++) {
          const isbn13 = this.worksheet[i]['isbn'].toString()
          if (!is_valid_isbn(isbn13)) {
            isbn13InvalidSet.add(isbn13)
          }
        }

        if (isbn13InvalidSet.size > 0) {
          this.error_msg = "Incorrecte ISBN-nummers gevonden: " + Array.from(isbn13InvalidSet).slice(0, 10).join(', ')
        }
      }

      if (has_nbd_column) {
        const invalidSet = new Set()
        for (let i = 0; i < this.worksheet.length; i++) {
          const orderNumber = this.worksheet[i]['bestelnummer'].toString()
          if (orderNumber.length != 10) {
            invalidSet.add(orderNumber)
          }
        }

        if (invalidSet.size > 0) {
          this.error_msg = "Incorrecte bestelnummers gevonden: " + Array.from(invalidSet).slice(0, 10).join(', ')
        }
      }
    },
    keys_to_lowercase(obj) {
      let key, keys = Object.keys(obj);
      let n = keys.length;
      const newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = obj[key] instanceof String ? obj[key].trim() : obj[key];
      }
      return newobj
    },
    set_label_strategy(value) {
      const label_strategy = this.label_options[value]
      this.label_option_selected = label_strategy.tag

      if (this.label_option_selected == 'Eigen labels') {
        this.parse_own_labels()
      }
      else if (this.label_option_selected == "OIB labels") {
        this.parse_oib_labels()
      } else if (this.label_option_selected == "NBD labels") {
        this.parse_nbd_labels()
      }
    },
    parse_own_labels() {
      // Get label column
      if (!('label' in this.worksheet[0])) {
        this.error_msg_option = 'Het bestand mist een kolom genaamd "label"'
        this.val_step_2()
        return
      } else {
        this.error_msg_option = null
      }

      // Update worksheet
      for (let i = 0; i < this.worksheet.length; i++) {
        this.worksheet[i]['label_picked'] = this.worksheet[i]['label'] == "" ? this.unknown_label_str : this.worksheet[i]['label'].trim()
        this.worksheet[i]['aantal'] = 'aantal' in this.worksheet[i] ? parseInt(this.worksheet[i]['aantal']) : 1
      }

      this.val_step_2()
    },
    async parse_oib_labels() {

      // Get label column
      if (!('isbn' in this.worksheet[0])) {
        this.error_msg_option = 'Het bestand mist een kolom genaamd "isbn"'
        this.val_step_2()
        return
      } else {
        this.error_msg_option = null
      }

      // Get all labels by isbn13
      const chunkSize = 100
      let isbn13Set = new Set()
      for (let i = 0; i < this.worksheet.length; i++) {
        isbn13Set.add(this.worksheet[i]['isbn'].toString())
      }
      const isbn13ChunkList = toChunks(Array.from(isbn13Set), chunkSize)

      // Get all books on the server side
      const jwt = this.$store.getters.get_jwt

      const label_dict = {}
      let isbn13SetServer = new Set()
      for (let i = 0; i < isbn13ChunkList.length; i++) {
        const json = await get_books(jwt, 'products', {
          'select': 'isbn13,book_type,avi,min_age,max_age',
          'isbn13': isbn13ChunkList[i].join(','),
          'limit': chunkSize
        })
        
        json['books'].forEach(book => {
          isbn13SetServer.add(book['isbn13'])
          const label = this.get_oib_label(book['book_type'], book['avi'], book['min_age'], book['max_age'])
          label_dict[book['isbn13']] = label
        })
      }

      const unknown_isbn13_set = isbn13Set.difference(isbn13SetServer)
      for (const isbn13 of unknown_isbn13_set) {
        const user_id = this.$store.getters.getUser['user_id']
        await post_book_products_users_scrape(jwt, isbn13, user_id)
      }

      // Parse result
      for (let i = 0; i < this.worksheet.length; i++) {
        const isbn13 = this.worksheet[i]['isbn'].toString()
        this.worksheet[i]['label_picked'] = label_dict[isbn13] || this.unknown_label_str
        this.worksheet[i]['aantal'] = 'aantal' in this.worksheet[i] ? parseInt(this.worksheet[i]['aantal']) : 1
      }

      this.val_step_2()
    },
    get_oib_label(book_type, avi, min_age, max_age) {
      const book_type_list = [
        'INFORMATIEF',
        'PRENTENBOEK',
        'VOORLEESBOEK',
        'SAMENLEESBOEK',
        'ZOEKBOEK',
        'AANWIJSBOEK',
        'DICHTBUNDEL'
      ]
      const avi_dict = {
        0: 'START',
        1: 'M3',
        2: 'E3',
        3: 'M4'
      }

      if (book_type_list.includes(book_type)) {
        return book_type
      } else if (avi > 0 && avi < 3) {
        return avi_dict[avi]
      } else if (min_age != null && min_age < 8) {
        return 'A'
      } else if (min_age != null && min_age < 11) {
        return 'B'
      } else if (min_age != null && min_age < 13) {
        return 'C'
      } else if (min_age != null && min_age < 100) {
        return 'D'
      } else if (max_age != null && max_age < 10) {
        return 'A'
      } else if (max_age != null && max_age < 13) {
        return 'B'
      } else if (max_age != null && max_age < 16) {
        return 'C'
      } else if (max_age != null && max_age < 100) {
        return 'D'
      } else {
        return this.unknown_label_str
      }
    },
    async parse_nbd_labels() {

      // Get nbd_order_number column
      if (!('bestelnummer' in this.worksheet[0])) {
        this.error_msg_option = 'Het bestand mist een kolom genaamd "bestelnummer"'
        this.val_step_2()
        return
      } else {
        this.error_msg_option = null
      }

      // Get all labels by nbd_order_number
      const chunkSize = 100
      let orderNumbers = new Set()
      for (let i = 0; i < this.worksheet.length; i++) {
        orderNumbers.add(this.worksheet[i]['bestelnummer'].toString())
      }
      const orderNumberChunkList = toChunks(Array.from(orderNumbers), chunkSize)

      // Get all books on the server side
      const jwt = this.$store.getters.get_jwt

      const label_dict = {}
      for (let i = 0; i < orderNumberChunkList.length; i++) {
        const json = await get_books(jwt, 'products', {
          'select': 'isbn13,nbd_order_number,nbd_label_age',
          'nbd_order_number': Array.from(orderNumbers).join(','),
          'limit': 10000
        })
        
        json['books'].forEach(book => {
          if (book['nbd_label_age'] == null) {
            book['nbd_label_age'] = this.unknown_label_str
          }
          label_dict[book['nbd_order_number']] = book['nbd_label_age']
        })
      }

      // Parse result
      for (let i = 0; i < this.worksheet.length; i++) {
        const nbd_order_number = this.worksheet[i]['bestelnummer']
        this.worksheet[i]['label_picked'] = label_dict[nbd_order_number] || this.unknown_label_str
        this.worksheet[i]['aantal'] = 'aantal' in this.worksheet[i] ? parseInt(this.worksheet[i]['aantal']) : 1
      }      

      // Identify and scrape missing books
      let unknown_count_nbd = 0
      const user_id = this.$store.getters.getUser['user_id']
      this.worksheet.forEach(row => {
        if (row['label_picked'] == this.unknown_label_str) {          
          post_book_products_users_scrape(jwt, row['isbn'], user_id) // TODO: force isbn in spreadsheet

          unknown_count_nbd += 1
        }
      })
      this.unknown_count_nbd = unknown_count_nbd

      this.val_step_2()
    },
    set_library_name(label, library_name) {
      this.label_dict[label] = library_name
      this.val_step_3()
    },
    async search_library_name(search_query) {
      const search_result = go(search_query, this.library_name_list, {threshold: -Infinity})
      let library_name_obj_list = []
      search_result.forEach(x => library_name_obj_list.push({'library_name': x.target}))
      return library_name_obj_list
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.worksheet != null && this.error_msg == null
    },
    async val_step_2() {
      this.form_step_validation_list[1] = this.label_option_selected != null && this.error_msg_option == null

      if (this.form_step_validation_list[1]) {
        this.label_dict = {}
        this.labels = new Set()        
        this.worksheet.forEach(x => {
          const label = x['label_picked']
          this.label_dict[label] = ""
          this.labels.add(label)
        })
      }
    },
    async val_step_3() {
      let valid = true
      for (let label of this.labels) {
        if (!this.library_name_list.includes(this.label_dict[label])) {
          valid = false
          break
        }
      }
      this.form_step_validation_list[2] = valid
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'worksheet': this.worksheet,
        'label_dict': this.label_dict        
      })
    }
  }
};
</script>
