import store from './index'
import router from '../router/index'

import { get_edition_isbn13_list } from './library'
import { get_can_borrow } from "./library";

export async function on_click_isbn13_router(user_id, book_edition_id, isbn13, isbn13_scanned, library_id_list, fast_borrow){

  // Get all isbn13 numbers related to this book_edition
  let isbn13_list = []
  if (isbn13_scanned) {
    isbn13_list = [isbn13_scanned.toString()]
  } else {
    isbn13_list = await get_edition_isbn13_list(book_edition_id)
  }
  const isbn13_set = new Set(isbn13_list)
  const copy = get_borrowed_copy(user_id, isbn13_set, library_id_list)

  // Activate library where this isbn13 was borrowed
  if (copy) {
    store.commit("set_active_library_id", copy.library_id)
  }

  // Check if this user is reading any the related book products
  const isbn13_reading = get_reading_isbn13(user_id, isbn13_set)
  if (isbn13_reading) {
    go_to_book_progress(user_id, isbn13_reading)
    return
  }

  let isbn13_final = null
  let scanned = null

  if (copy) {
    isbn13_final = copy.isbn13
    scanned = true
  } else if (isbn13_scanned) {
    isbn13_final = isbn13_scanned
    scanned = true
  } else {
    isbn13_final = isbn13
  }

  if (fast_borrow && !copy) {
    go_to_book_borrow(user_id, book_edition_id, isbn13_final, fast_borrow)
  } else {
    go_to_book(user_id, book_edition_id, isbn13_final, scanned)
  }
}

export async function on_done_isbn13_router(user_id, book_edition_id, isbn13_scanned, library_id_list){
  
  // Get all isbn13 numbers related to this book_edition
  let isbn13_list = []
  if (isbn13_scanned) {
    isbn13_list = [isbn13_scanned.toString()]
  } else {
    isbn13_list = await get_edition_isbn13_list(book_edition_id)
  }
  const isbn13_set = new Set(isbn13_list)
  const copy = get_borrowed_copy(user_id, isbn13_set, library_id_list)

  // Activate library where this isbn13 was borrowed
  if (copy) {
    store.commit("set_active_library_id", copy.library_id)
  }

  // Check whether user can return book


  // Go to book screen if this book with specific isbn13 if this book was borrowed in this library
  if (copy && get_can_borrow(user_id)) {
    go_to_book_replace(user_id, book_edition_id, copy.isbn13, true)
  } else {
    const user_id_logged_in = store.getters.getUser['user_id']
    if (user_id_logged_in == user_id) {
      setTimeout(() => open_library_reading(), 200)
    } else {
      open_student_overview(user_id)
    }
  }
}

function get_reading_isbn13(user_id, isbn13_set) {

  // Skip roles that are unable to track progress
  const role_list = store.getters.getUsers[user_id]['role']
  if (!(role_list.includes('STUDENT') || role_list.includes('TEACHER') || role_list.includes('LIBRARIAN'))) {
    return null
  }
  
  // Get isbn13 list of books that user is reading
  const isbn13_set_reading = store.getters.get_progress_state_dict[user_id][1];
  const intersection = get_intersection_set(isbn13_set, isbn13_set_reading)
  return intersection.size > 0 ? intersection.values().next().value : null
}

function get_borrowed_copy(user_id, isbn13_set, library_id_list) {
  if (!store.getters.get_books_borrowed[user_id]) {
    return null
  }

  for (let i = 0; i < library_id_list.length; i++) {
    const library_id = library_id_list[i]
    const list_borrowed = store.getters.get_books_borrowed[user_id][library_id]
    if (!list_borrowed) {
      continue
    }

    let isbn13_list_borrowed = []
    list_borrowed.forEach(x => isbn13_list_borrowed.push(x['isbn13']));
    const intersection = get_intersection_set(isbn13_set, new Set(isbn13_list_borrowed))
    if (intersection.size > 0) {
      return {
        'isbn13': intersection.values().next().value,
        'library_id': library_id
      }
    }
  }

  return null
}

function get_intersection_set(set_0, set_1) {
  const result = new Set([...set_0].filter(i => set_1.has(i)));
  return result
}

function go_to_book(user_id, book_edition_id, isbn13, scanned) {
  router.push({
    name: "Boek",
    params: {
      user_id: user_id,
      book_edition_id: book_edition_id,
      isbn13: isbn13,
      scanned: scanned
    },
  });
}
function go_to_book_replace(user_id, book_edition_id, isbn13, scanned) {
  router.replace({
    name: "Boek",
    params: {
      user_id: user_id,
      book_edition_id: book_edition_id,
      isbn13: isbn13,
      scanned: scanned
    },
  });
}

function go_to_book_borrow(user_id, book_edition_id, isbn13, fast_borrow=0) {
  router.push({
    name: "BoekBiebKiezen",
    params: {
      'user_id': user_id,
      'book_edition_id': book_edition_id,
      'isbn13_scanned': isbn13,
      'action': 'lenen',
      'fast_borrow': fast_borrow
    }
  });
}
function go_to_book_progress(user_id, isbn13) {
  router.push({
    name: "BoekVoortgang",
    params: {
      user_id: user_id,
      isbn13: isbn13,
    },
  });
}

function open_library_reading() {
  router.push({
    name: "BibliotheekLezen"
  });
}

function open_student_overview(user_id) {
  router.push({
    name: "OverzichtLeerling",
    params: {
      user_id: user_id,
    },
  });
}