<template>
    <input 
    v-model="search_query"
    type="text"
    spellcheck="false"
    :placeholder="placeholder"
    :class="class_str"
    class="placeholder"
    ref="search_bar"
    @focus="on_focus"
    @keyup.enter="handle_search_queue_final"
    @keyup.esc="handle_search_queue_final"
    >
</template>

<script>
export default {
  name: "OibSearchBar",
  props: {
    init_value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      default: "Zoek en filter ..."
    },
    show_search_icon: {
      type: Boolean,
      default: true
    },
    shape: {
      type: String,
      default: 'round'
    },
    search_frequency_ms: {
      type: Number,
      required: false,
      default: 1000
    }
  },
  data() {
    return {
      search_query: '',
      search_query_prev: '',
      search_request_queued: false
    }
  },
  computed: {
    class_str() {
      let class_str = ""
      if (this.show_search_icon) class_str += 'show-search-icon '
      if (this.shape == 'round') class_str += 'round '
      return class_str
    }
  },
  watch: {
    init_value() {
      this.search_query = this.init_value
    },
    search_query() {
      if (this.search_query != this.init_value) {
        this.queue_search_request()
      }      
    }
  },
  mounted() {
    this.search_query = this.init_value
  },
  methods: {
    focus() {
      setTimeout(() => this.$refs.search_bar.focus(), 10)
    },
    blur() {
      this.$refs.search_bar.blur()
    },
    queue_search_request() {
      // Requeue a check
      if (this.search_query_prev != this.search_query && !this.search_request_queued) {
        this.search_request_queued = true
        setTimeout(this.handle_search_queue, this.search_frequency_ms)
      }
    },
    handle_search_queue_final() {
      this.handle_search_queue()
      if (this.$mq == 'mobile') {
        this.blur()
      }
    },
    handle_search_queue() {
      // Skip if the queued query was already executed through a keyup event
      if (this.search_query_prev == this.search_query) {
        return
      }

      this.search_query_prev = this.search_query
      this.search_request_queued = false
      this.$emit('search', this.search_query)
    },
    on_focus() {
      this.search_query_prev = ''
      this.queue_search_request()
    },
    clear() {
      this.$refs.search_bar.value = ''
      this.$refs.search_bar.dispatchEvent(new InputEvent('input', {
        'isTrusted': true,
        'data': '',
        'inputType': 'insertText'
      }))
    }
  }
}
</script>

<style scoped>
input {
    width: 100%;
    max-width: 500px;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px 10px 16px;
    border: none;
    caret-color: var(--highlight-color)
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

.show-search-icon {
  padding: 10px 16px 10px 40px;
  background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.round {
  border-radius: 10px;
}

* {  
  box-sizing: border-box;
}

.placeholder::placeholder {
  color: var(--contrast-color-p2)
}

</style>