<template>
  <oib-page>
    <oib-nav-student :allowLogout="false" :show_back="false">
      <template v-slot:header><slot name="header">
        <!-- <oib-header-search
        placeholder="Stel een vraag"
        @click_searchbar="go_to_stel_een_vraag()"
        :show_back_button="false"
        :show_logout_button="true"
        >
        <template v-slot:search_collection>
          <a
          :href="$mq == 'desktop' ? 'https://wa.me/310643150049' : 'whatsapp://send?phone=310643150049'"
          style="background-color: #25d366; border-radius: 2px; padding: 5.5px 3px; width: 100px; margin-right: 10px"
          >
            <div class="row" style="display: flex; flex-direction: row; align-items: center; justify-content: center">
              <img
              style="height: 20px; width: 20px;"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/512px-WhatsApp.svg.png?20220228223904"
              />
              <p style="margin: 0 0 0 10px; font-size: 0.7em; color: white; font-weight: bold">WhatsApp</p>
            </div>
          </a>
        </template>
        </oib-header-search> -->
      </slot></template>
    </oib-nav-student>
    <oib-content padding_top="padding_top">
      <section class="wrapper column" style="margin-bottom: 20px;">
        <h1 class="h1" style="margin-top: 0">Winkel</h1>
        <div class="column" style="width: 100%; max-width: 350px;">
          <h4 class="h4" style="z-index: 1">Binnenkort in dit scherm</h4>
          <oib-checklist-static
            :required="true"
            :options="[
              {task: true, title: 'Wens- en bestellijsten delen met partners', 'page': 'GebruikersLeerkrachten'},
              {task: true, title: 'Suggesties voor aanvulling boekencollectie', 'page': 'GebruikersLeerkrachten'},
              {task: true, title: 'Bestelstatus volgen', 'page': 'GebruikersLeerkrachten'},
              {task: true, title: 'Facturen overzicht', 'page': 'GebruikersLeerkrachten'},
            ]"
          />
        </div>
        <div class="row" style="max-width: 700px; flex-wrap: wrap; justify-content: space-between;">
          <oib-tile-img
            v-if="$store.getters.get_school_id_active && partner_organisation_name"
            class="two-tiles"
            :img_url="get_organisation_image_url(partner_organisation_id)"
            icon="handshake"
            icon_family="symbol"          
            :header="partner_organisation_name"
            :body="'Je deelt de boekencollectie met ' + partner_organisation_name + '.'"
          ></oib-tile-img>
          <oib-tile-img
            v-if="$store.getters.get_school_id_active && !partner_organisation_id"
            class="two-tiles"
            img_url="https://onderwijsinbeeld.nl/assets/img/bibliotheek_onderwijs_in_beeld_small.webp"
            icon="handshake"
            icon_family="symbol"
            header="Partner uitnodigen"
            body="Deel de boekencollectie met jullie biebpartner voor beter advies op maat."
            @click="go_to_route('OntdekkenPartners')"
          ></oib-tile-img>
          <oib-tile-img
            class="two-tiles"
            img_url="https://onderwijsinbeeld.nl/assets/img/boek_labels.jpeg"
            icon="shopping_cart"
            icon_family="symbol"
            header="Materialen shop"
            body="Bestel hier nieuwe boeklabels voor jullie schoolbibliotheek."
            @click="go_to_link('https://schoolbiebwinkel.nl')"
          ></oib-tile-img>
        </div>
      </section>     
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibTileImg from "../components/oib_tile_img.vue"
import OibChecklistStatic from "../components/oib_checklist_static.vue"

import {get_bucket} from "../store/bucket.js"
import {get_organisations} from '../store/api/user.js'
import {get_schools_partners_query} from '../store/api/user.js'
import { go_to_route } from "../store/navigation";

export default {
  name: "Support",
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibTileImg,
    OibChecklistStatic
  },
  computed: {
    can_manage_library() {
      const user = this.$store.getters.getUser
      if (!user) {
        return false
      }
      const role_list = user['role']
      return this.filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'], role_list)
    },
    action() {
      const user = this.$store.getters.getUser
      if (!user) {
        return false
      }

      const role_list = user['role']
      if (!role_list) {
        return
      }
      if (this.filter_roles(['SCHOOL_ADMIN'], role_list) && !this.task_finished('LINK_LVS')) {
        return {
          text: 'Koppel het leerlingvolgsysteem',
          route: 'GebruikersLeerkrachten'
        }
      } else if (this.filter_roles(['SCHOOL_ADMIN'], role_list) && !this.task_finished('ADD_TEACHER_ACCOUNTS')) {
        return {
          text: 'Voeg 5 accounts toe aan leerkrachten',
          route: 'GebruikersLeerkrachten'
        }
      }else if (this.filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN'], role_list) && !this.task_finished('ADD_LIBRARY')) {
        return {
          text: 'Voeg 3 bibliotheken toe',
          route: 'BibliotheekCollectie'
        } 
      } else if (this.filter_roles(['LIBRARIAN'], role_list) && !this.task_finished('ADD_LIBRARY_BOOKS')) {
        return {
          text: 'Voeg 100 boeken toe aan de bibliotheek',
          route: 'BibliotheekCollectie'
        }
      } else if (this.filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN'], role_list) && !this.task_finished('LOAD_STUDENTS')) {
        return {
          text: 'Laad de leerlingen in',
          route: 'GebruikersLeerlingen'
        } 
      } else if (this.filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN'], role_list) && !this.task_finished('LINK_STUDENT_QR_CODES')) {
        return {
          text: 'Koppel een boekenlegger',
          route: 'GebruikersLeerlingen'
        } 
      } else if (this.filter_roles(['LIBRARY_ASSISTENT', 'LIBRARIAN'], role_list) && !this.task_finished('LOAD_READING_TESTS')) {
        return {
          text: 'Laad de leestoetsen in',
          route: 'GebruikersLeerlingen'
        } 
      }
      return null
    }
  },
  methods: {
    // Import
    go_to_route,

    // Local
    task_finished(task_title) {
      const task_dict = this.$store.getters.get_task_dict
      return task_title in task_dict && task_dict[task_title].finish_datetime != null
    },
    async load_school_partner() {
      const jwt = this.$store.getters.get_jwt
      const school_id = this.$store.getters.get_school_id_active
      if (!school_id) {
        return
      }

      const school = this.$store.getters.get_school
      this.branch_number = school['brin'] + school['dependance_code']

      let organisation_id = this.$store.getters.get_organisation_id 
      let organisation_id_active = this.$store.getters.get_organisation_id_active
      if (organisation_id == organisation_id_active || organisation_id == 1) {
        const json = await get_schools_partners_query(jwt, {'school_id': school_id})
        const school_partner_list = json['school_partner_list']
        if (school_partner_list.length == 0) {
          return
        }

        this.partner_organisation_id = school_partner_list[0].organisation_id
      } else{
        this.partner_organisation_id = organisation_id
      }
      
      const organisation = await get_organisations(jwt, this.partner_organisation_id)
      this.partner_organisation_name = organisation.organisation_name    
    },
    search(search_query) {

      // If search query is empty - display all questions
      if (search_query == "") {
        this.display_all()
        return
      }

      const search_words = search_query.toLowerCase().split(" ")
      let match_points = {}
      for (let i = 0; i < this.q_and_a.length; i++) {
        match_points[i] = 0
        const title = this.q_and_a[i]['title']
        const keywords = this.q_and_a[i]['keywords']
        for (let k = 0; k < search_words.length; k++) {
          if (title.includes(search_words[k])) {
            match_points[i] += 1
          }
          for (let x = 0; x < keywords.length; x++) {
            const keyword = keywords[x]
            if (search_words[k].includes(keyword)) {
              match_points[i] += 2
            }
          }
        }
      }

      const dsu = (arr1, arr2) => arr1
        .map((item, index) => [arr2[index], item]) // add the args to sort by
        .sort(([arg1], [arg2]) => arg2 - arg1) // sort by the args
        .map(([, item]) => item); // extract the sorted items

      let keys = []
      let values = []
      for (const [key, value] of Object.entries(match_points))  {
        
        // Filter failed matches
        if (value > 0) {
          keys.push(key)
          values.push(value)
        }        
      }

      this.search_indices = dsu(keys, values)
      this.search_indices.length = Math.min(this.search_indices.length, 4);
      
      let result_list = []
      this.search_indices.forEach(x => result_list.push(this.q_and_a[x]))

      if (result_list.length == 0) {
        result_list = [{'title': 'Niets gevonden'}]
      }

      return result_list
    },
    display_all(){
      this.search_indices = []
      for (var i = 0; i < this.q_and_a.length; i++) {
        this.search_indices.push(i);
      }
    },
    go_to_stel_een_vraag() {
      go_to_route('StelEenVraag')
    },
    go_to_link(href) {
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      setTimeout(() => {
        window.open(href, '_blank');
      })
    },
    filter_roles(valid_roles, user_roles) {
      for (let i = 0; i < valid_roles.length; i++) {
        for (let k = 0; k < user_roles.length; k++) {
          if (valid_roles[i] == user_roles[k]) {
            return true
          }
        }
      }

      return false;
    },
    get_organisation_image_url(organisation_id) {
      return `${get_bucket('media')}/organisations/l/${organisation_id}.webp`
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.user_id = user['user_id']
    
    if(this.can_manage_library) {
      this.load_school_partner()
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.display_all() 
  },
  data() {
    return {
      branch_number: null,
      partner_organisation_id: null,
      partner_organisation_name: '',
      search_query: "",
      search_indices: [],
      // Related pages are based on keywords
      q_and_a: [
      {
          "title": "Hoe installer ik de app op Android-devices?",
          "href": "https://play.google.com/store/apps/details?id=nl.onderwijsinbeeld.leesapp",
          "keywords": ["installeren", 'android', 'samsung', 'store', 'download', 'telefoon', 'mobiel'],
        },
        {
          "title": "Hoe installer ik de app op Apple-devices?",
          "href": "https://apps.apple.com/us/app/de-leesapp/id1637641808",
          "keywords": ["installeren", 'iphone', 'ios', 'store', 'download', 'telefoon', 'mobiel'],
        },
        {
          "title": "Hoe voeg ik een account toe?",
          "href": "https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#hoe_voeg_ik_accounts_toe",
          "keywords": ["account", 'leerkracht', 'ouder'],
        },
        {
          "title": "Hoe koppel ik het leerlingvolgsysteem?",
          "href": "https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding",
          "keywords": ["lvs", 'volg', 'parna', 'esis'],
        },
        {
          "title": "Hoe koppel ik een boekenlegger?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boekenleggers_koppelen",
          "keywords": ["inloggen", "boekenlegger", 'qr', 'kwijt'],
        },
        {
          "title": "Help de boekenlegger is kwijt!",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#help_de_boekenlegger_is_kwijt",
          "keywords": ["inloggen", "boekenlegger", 'qr', 'kwijt', 'opnieuw'],
        },
        {
          "title": "Hoe geef ik boek voortgang door?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boek_voortgang_doorgeven",
          "keywords": ["voortgang"],
        },
        {
          "title": "Hoe stop ik met een boek?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#hoe_stop_ik_met_een_boek",
          "keywords": ["voortgang", "stop"],
        },
        {
          "title": "Hoe voeg ik een bibliotheek toe?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#bibliotheken_toevoegen",
          "keywords": ["toevoeg"],
        },
                {
          "title": "Hoe voeg ik een boek toe aan de bibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#boeken_inscannen",
          "keywords": ["toevoeg", "scan"],
        },
        {
          "title": "Hoe leent een leerling een boek?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boeken_uitlenen",
          "keywords": ["leen", "lenen"],
        },
        {
          "title": "Hoe levert een leerling een boek in?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boeken_inleveren",
          "keywords": ["lever", 'terug'],
        },
        {
          "title": "Kunnen leerlingen ook een boek van thuis lezen?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#kunnen_leerlingen_ook_een_boek_van_thuis_lezen",
          "keywords": ["boek", 'leen', 'lenen', "lever", 'uitle', 'thuis', 'eigen'],
        },
        {
          "title": "Wie gaat de boeken uitlenen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#wie_gaat_de_boeken_uitlenen",
          "keywords": ["leen", "lenen"],
        },
        {
          "title": "Welke apparaten kunnen we gebruiken?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#geschikte_devices",
          "keywords": ["tablet", 'laptop', 'chromebook', "devic", 'appara', 'geschikt', 'ipad'],
        },
        {
          "title": "Hoe rubriceren we de schoolbibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_rubriceren_we_de_schoolbibliotheek",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori'],
        },
        {
          "title": "Hoe categoriseren we de schoolbibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#saneren_en_organiseren",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori'],
        },
        {
          "title": "Welke labels plakken we op de boeken?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#welke_labels_plakken_we_op_de_boeken",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori', 'label'],
        },
        {
          "title": "Boeken op een centrale plek of verspreid over klassen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#boeken_op_een_centrale_plek_of_verspreid_over_klassen",
          "keywords": ['klas', 'centra', 'locatie'],
        },
        {
          "title": "Welke boeken gaan we saneren?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#welke_boeken_gaan_we_saneren",
          "keywords": ['sane', 'opruim', 'opscho'],
        },
        {
          "title": "Hoe vaak mogen leerlingen een boek ruilen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_vaak_mogen_leerlingen_een_boek_ruilen",
          "keywords": ['ruil', 'lenen'],
        },
        {
          "title": "Hoe houden we de bieb op orde?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_houden_we_de_bieb_op_orde",
          "keywords": ['opruim', 'schoon', 'orde', 'netjes'],
        },
        {
          "title": "Hoe houd ik de bieb toegankelijk?",
          "href": "https://onderwijsinbeeld.nl/hoe-maak-je-de-schoolbieb-toegankelijker/",
          "keywords": ['toegang', 'zichtbaar'],
        }
      ]
    }
  }
};
</script>

<style scoped>

.background-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%);
}

.tile-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
}

.tile-grid div{
  width: 100%;
  height: 150px;
}

.two-tiles {
  width: 100%;
  height: 220px;
}

@media screen and (min-width:600px) {
  .two-tiles {
    width: 43%;
    height: 300px;
    margin-right: 0;
  }
}

@media screen and (min-width:740px) {
  .tile-grid div{
    width: 220px;
    height: 230px;
  }

  .two-tiles {
    width: 44%;
    height: 300px;
    margin-right: 0;
  }
}

@media screen and (min-width:1230px) {
  .tile-grid div{
    width: 270px;
    height: 230px;
  }

  .two-tiles {
    width: 45%;
    height: 300px;
  }
}

.search-result {
  color: var(--primary-color);
  padding: 2px 0 2px 15px;
  font-weight: 400;
}

.search-result:hover {
  cursor: pointer;
}

.search-result p {
  color: var(--primary-color);
  font-size: 0.8em;
}

.action {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--primary-color-sub);
  padding: 20px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  max-width: 660px;
}

.action:hover {
  background-color: var(--primary-color-p3);
  cursor: pointer;
}

.action p {
  margin: 0;
  font-weight: bold;
  color: white;
  font-size: 0.9em;
}

/* p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.tile {
  display: flex;
  flex-direction: column;
  height: 280px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34);
}

.tile:hover {
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70);
  cursor: pointer;
}

@media screen and (min-width:320px) {
  .tile {
    width: 100%;
  }
}

@media screen and (min-width:1050px) {
  .tile {
    width: 700px;
    height: 350px;
    margin-right: 40px;
  }
}

.tile-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%);
} */

</style>
