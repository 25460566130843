<template>
  <oib-page-loading :progress="progress" :text="text">
    <p :style="text_style">{{text}}...</p>
  </oib-page-loading>
</template>

<script>
import OibPageLoading from "../components/oib_page_loading.vue";

import { load_book_progress_states } from "../store/book_progress.js"
import {load_library_user_access} from "../store/library.js"
import {login_library_module} from "../store/library.js"
import {get_organisations} from "../store/api/user.js"
import {get_schools_query} from "../store/api/user.js"
import {get_groups_query} from "../store/api/user.js"
import {get_buildings_query} from "../store/api/user.js"
import {get_classrooms_query} from "../store/api/user.js"
import {load_location_selectors} from '../store/user'
import {init_library_selectors} from "../store/library"
import {load_libraries} from "../store/library"
// import {validate_lvs} from "../store/lvs"
import {has_role} from "../store/utils"

export default {
  name: "Loading",
  components: {
    OibPageLoading
  },
  data() {
    return {
      progress: 0,
      api_call_count: 0,
      role: null,
      user_id: null,
      organisation_id: null,
      school_id: null,
      text: "Account laden",
      text_style: ''
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login"
      });
    }
  },
  async mounted() {

    // Set login tag
    const self = this
    const jwt = this.$store.getters.get_jwt
    const user = this.$store.getters.getUser
    this.text = 'Log in als ' + user.user_name

    this.user_id = user['user_id']
    this.role = user['role']
    this.organisation_id = this.$store.getters.get_organisation_id_active

    if (this.role.length == 0) {
      this.text = this.$store.getters.getUser.user_name + 'heeft geen rol'
      this.text_style = 'color: red'
      return
    }

    // System admin only loads schools
    if ((has_role(['SYSTEM_ADMIN']) && this.organisation_id == 1) || (has_role(['LIBRARY_PARTNER']) && this.$store.getters.get_organisation_id == this.$store.getters.get_organisation_id_active)) {
      this.api_call_count = 1
      self.increment_progress()
    }

    // Other users load schools, groups and user
    else {

      if (has_role(['STUDENT', 'TEACHER'])) {
        this.api_call_count = 9
      } else if (has_role(['LIBRARIAN', 'LIBRARY_ASSISTENT'])) {
        this.api_call_count = 8
      } else if (has_role(['SUPERVISOR'])) {
        this.api_call_count = 5
      } else if (has_role(['SCHOOL_ADMIN'])) {
        this.api_call_count = 4
      } else if (has_role(['SYSTEM_ADMIN'])) {
        this.api_call_count = 5
      }

      get_organisations(jwt, this.organisation_id).then(organisation => {
        self.$store.commit("set_organisation", organisation)
        self.on_organisation_loaded()
      })

      if (has_role(['STUDENT', 'TEACHER'])) {
        this.load_group_users().then(() => {
          self.increment_progress()
        })
      }
      if (has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN'])) {

        // Get borrowed books
        this.$store.dispatch("load_books_borrowed", {
          'jwt': jwt,
          'user_id': this.user_id
        }).then(() => {
          self.increment_progress()
          
          // Get book progress of books where state=READING
          const READING = 1
          const READ = 2
          load_book_progress_states(this.user_id, READING).then(() => self.increment_progress())
          load_book_progress_states(this.user_id, READ).then(() => self.increment_progress())
        })
      }
    }
  },
  methods: {
    config_google_tag_manager() {

      // Get user type for google tag manager
      let user_type = null
      if (has_role(['STUDENT'])) {
        user_type = 'STUDENT'
      } else if (has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SCHOOL_ADMIN', 'SUPERVISOR'])) {
        user_type = 'TEACHER'
      } else if (has_role(['LIBRARY_PARTNER'])) {
        user_type = 'LIBRARY_PARTNER'
      } else if (has_role(['SYSTEM_ADMIN'])) {
        user_type = 'SYSTEM_ADMIN'
      }

      // Configure user config for google analytics
      let config = {
        user_id: this.user_id,
        user_type: user_type,
        organisation_id: this.organisation_id
      }

      if (user_type == 'STUDENT' || user_type == 'TEACHER') {
        const school = this.$store.getters.get_school
        config['branch_number'] = school.brin + school.dependance_code
      }

      window.dataLayer = window.dataLayer || [];
      function gtag () {
        window.dataLayer.push(arguments)
      }
      gtag('set', 'user_properties', config)
    },
    async load_group_users() {
      const jwt = this.$store.getters.get_jwt
      const json = await get_groups_query(jwt, {
        user_id: this.user_id,
        organisation_id: this.organisation_id,
        schoolyear: this.$store.getters.get_schoolyear
      })
      const group_list = json['group_list']

      if (group_list.length == 0) {
          this.text = this.$store.getters.getUser.user_name + ' is niet gekoppeld aan een klas'
          this.text_style = 'color: red'
          return
      }

      if (group_list.length > 0) {
        json["group_list"].forEach(group => this.$store.commit("add_group_user", {
          'group_id': group['group_id'],
          'user_id': this.user_id
        }))
      }
    },
    async increment_progress() {
      this.progress += (100 / this.api_call_count)
      this.attempt_to_finish()
    },
    async on_organisation_loaded() {

      this.increment_progress()

      const self = this
      const jwt = this.$store.getters.get_jwt

      // school
      get_schools_query(jwt, {'organisation_id': this.organisation_id}).then(json => {
        if (json['school_list'].length > 0) {
          const school = json['school_list'][0]
          this.school_id = school['school_id']
          self.$store.commit("set_school", school)
          self.$store.commit("set_school_id_active", this.school_id)
          self.on_school_loaded()
        }
      })
      
      // buildings
      get_buildings_query(jwt, {'organisation_id': this.organisation_id}).then(json => {
        json['building_list'].forEach(building => self.$store.commit("add_building", building))
        self.increment_progress()
      })

      // classrooms
      get_classrooms_query(jwt, {
        'organisation_id': this.organisation_id,
        'schoolyear': this.$store.getters.get_schoolyear
      }).then(json => {
          json['classroom_list'].forEach(classroom => self.$store.commit("add_classroom", classroom))
          self.increment_progress()
          self.on_classrooms_loaded()
      })

      // libraries
      if (has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])) {
        load_libraries().then(() => self.increment_progress())
      }
      // login_library_module()
    },
    async on_school_loaded() {
      if (has_role(['SCHOOL_ADMIN', 'SYSTEM_ADMIN'])) {
        const school = this.$store.getters.get_school
        const branch_number = school.brin + school.dependance_code
        this.$store.dispatch("load_lvs", {
          'jwt': this.$store.getters.get_jwt,
          'branch_number': branch_number
        })

        // validate_lvs(branch_number)
      }
    },
    async on_classrooms_loaded() {
      const self = this
      const jwt = this.$store.getters.get_jwt
      const classroom_dict = this.$store.getters.get_classroom_dict
      const classroom_id_list = Object.keys(classroom_dict)

      if (classroom_id_list.length > 0 && has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])) {
        const classroom_id_list_str = classroom_id_list.join(',')
        this.$store.dispatch("load_classroom_borrow_independence", {
          jwt: jwt,
          classroom_id: classroom_id_list_str
        })
      }

      get_groups_query(jwt, {
        'organisation_id': this.organisation_id,
        'schoolyear': this.$store.getters.get_schoolyear
      }).then(json => {
        json['group_list'].forEach(group => {
            self.$store.commit("add_group", group)
            self.$store.commit("add_classroom_active", classroom_dict[group['classroom_id']])
        })
        self.increment_progress()
      })
    },
    async attempt_to_finish() {
      if (this.progress < 100) {
          return
      }

      if (has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR'])) {
        this.$store.commit("compute_user_access_dict", this.user_id)
        load_library_user_access(this.user_id)
        load_location_selectors()
        init_library_selectors()
        login_library_module()
      }

      const school = this.$store.getters.get_school
      if (school && has_role(['SCHOOL_ADMIN', 'LIBRARIAN', 'LIBRARY_ASSISTENT', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])) {
        await this.$store.dispatch("load_task_dict", {
          'jwt': this.$store.getters.get_jwt,
          'organisation_id': school.organisation_id,
          'school_id': school.school_id,
          'branch_number': school.brin + school.dependance_code
        })
      }

      this.config_google_tag_manager()

      setTimeout(() => {
        this.$store.commit("setUserDataLoaded", true);

        // Pass users to their default screen
        if (has_role(['STUDENT'])) {
          this.openLezen()
        }
        if (has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'SCHOOL_ADMIN'])) {
          this.openHome(this.user_id)
        }
        if (has_role(['LIBRARY_PARTNER', 'SYSTEM_ADMIN'])) {
          if (this.$store.getters.get_school) {
            this.openBibliotheekCollectie(this.user_id)
          } else {
            this.openScholen()
          }
        }
      }, 400)
    },
    openLezen() {
      this.$router.push({
        name: "BibliotheekLezen"
      });
    },
    openHome(user_id) {
      this.$router.push({
        name: "Support",
        params: {
          user_id: user_id,
        },
      });
    },
    openBibliotheekCollectie(user_id) {
      this.$router.push({
        name: "BibliotheekCollectie",
        params: {
          user_id: user_id,
        },
      });
    },
    openScholen() {
      this.$router.push({
        name: "Scholen"
      });
    },
  }
};
</script>