<template>
  <oib-page style="z-index: 4">
    <oib-nav-student :title="title">
      <template v-slot:right>
        <oib-button-icon icon="refresh" ref="refresh" @click="reload_libraries" style="font-size: 24px;"/>
      </template>
    </oib-nav-student>
    <oib-content>
      <div style="margin-top: 10px; margin-bottom: 100px;">
        <div v-if="selector_map.length == 0" class="wrapper">
          <p class="description">Je kunt geen bieb kiezen omdat er nog geen bieb is toegevoegd. Lees ook: 
            <a href="https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#bibliotheken_toevoegen" target="_blank">
              Hoe voeg ik een nieuwe bieb toe?
            </a>
          </p>
        </div>
        <div v-for="(value, name) in selector_map" :key="name">
          <div v-if="value.children">
            <div class="wrapper">
              <div class="row" style="justify-content: space-between; align-items: center; width: 100%; height: 82px;">
                <div class="row clickable" style="align-items: center;" @click="value.active=!value.active">
                  <span v-if="value.children.length > 0" class="material-icons next">{{value.active ? 'expand_less' : 'expand_more'}}</span>
                  <h3 class="h4" style="padding-left: 5px; ">{{ value.selector.name }}</h3>
                </div>
                <div style="width: 100px; text-align: right;">
                  <oib-button
                    style="width: 80px; padding: 5px; height: 30px;"
                    @click="submit(value.selector.index)"
                  >selecteren</oib-button>
                </div>
              </div>
            </div>
          </div>
          <oib-list-item-button
              v-if="!value.children"
              :image_url="get_image_url(value.query.library_id)"
              :header="value.name"
              :text="value.location_name"
              button_text="selecteren"
              @click="submit(value.index)"
            />
          <div v-if="value.active">
            <oib-list-item-button
              v-for="value_child in value.children" :key="value_child.name"
              :image_url="get_image_url(value_child.query.library_id)"
              :header="value_child.name"
              :text="value_child.location_name"
              button_text="selecteren"
              @click="submit(value_child.index)"
            />
          </div>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibListItemButton from "../components/oib_list_item_button.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue";
import OibButton from "../components/OibButton.vue"

import {get_bucket} from "../store/bucket.js"
import { reload_libraries } from "../store/library";

export default {
  name: "LibrarySelector",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibListItemButton,
    OibButtonIcon,
    OibButton
  },
  props: {
    title: {
      default: "Bieb filter kiezen",
      type: String
    }
  },
  data() {
    return {
      selector_map: {}
    }
  },
  created() {
    this.init_selector_map()
  },
  methods: {
    get_image_url(library_id) {
      return `${get_bucket('media')}/libraries/s/${library_id}.webp`
    },
    submit(index) {
      this.$store.commit("set_active_library_selector", index)
      this.$emit("submit")
    },
    async reload_libraries() {
      await reload_libraries()
      this.$refs.refresh.$el.classList.add('rotate')
      setTimeout(this.stop_refresh_anim, 200)
      this.init_selector_map()
    },
    stop_refresh_anim() {
      this.$refs.refresh.$el.classList.remove('rotate')
    },
    init_selector_map() {
      let selector_map = {}
      const selector_list = this.$store.getters.get_library_selector_list

      // Get all selector groupings
      let selector_children_list = []
      for (let i = 0; i < selector_list.length; i++) {
        const selector = selector_list[i]
        if (selector.query.library_id.includes(',') || selector.name == 'School' || selector.name == 'Overige') {
          selector_map[selector['location_name']] = {
            'selector': selector,
            'children': [],
            'active': true
          }
        } else {
          selector_children_list.push(selector)
        }
      }

      // Add children to selector groups
      for (let i = 0; i < selector_children_list.length; i++) {
        const selector_child = selector_children_list[i]
        if (selector_child['location_name'] in selector_map) {
          selector_map[selector_child['location_name']]['children'].push(selector_child)
        } else if ('Overige' in selector_map) {
          selector_map['Overige']['children'].push(selector_child)
        } else {
          selector_map[selector_child['name']] = selector_child
        }
      }

      // Replace "Overige" with other name in case it only contains one library
      if ('Overige' in selector_map && selector_map['Overige']['children'].length == 1) {
        const child = selector_map['Overige']['children'][0]
        selector_map[child['location_name']] = {
          'selector': child,
          'children': [child],
          'active': true
        }
        selector_map[child['location_name']]['selector']['name'] = child['location_name']
        delete selector_map['Overige']
      }      

      this.selector_map = selector_map
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
}

.swiper-slide { width: auto; }

.tags-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.rotate {
  animation: rotation .2s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
