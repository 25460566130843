import store from './index'
import {post_libraries_authenticate} from './api/library.js'
import {get_libraries_books_copies} from './api/library.js'
import {put_libraries_books_copies_users_borrow} from './api/library.js'
import {get_books} from './api/library.js'
import {add_progress} from './book_progress'
import { get_datetime_str, has_role } from './utils'
import {get_libraries_query} from "./api/library"
import {get_tags_query} from './api/book'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function get_available_copy_id (jwt, library_id, isbn13) {
  const query = {
    'select': 'copy_id,user_id',
    'library_id': library_id,
    'isbn13': isbn13,
    'limit': 1000000,
    'page': 0
  }
  const json = await get_libraries_books_copies(jwt, query)
  const copy_list = json['copy_list']

  // Get available copy_id
  let copy_id = null
  for (let i = 0; i < copy_list.length; i++) {
    const book_copy_user = copy_list[i]
    if (book_copy_user['user_id'] == null) {
      copy_id = book_copy_user['copy_id']
      break
    }
  }
  return copy_id
}

export async function load_libraries() {

  // Load libraries
  const jwt = store.getters.get_jwt
  const organisation_id = store.getters.get_organisation_id_active
  const json = await get_libraries_query(jwt, {'organisation_id_access': organisation_id})
  store.commit("set_libraries", json['library_list'])

  // Sync task
  if (has_role(['SYSTEM_ADMIN', 'READING_CONSULTANT', 'LIBRARIAN', 'LIBRARY_ASSISTENT', 'SUPERVISOR'])) {
    store.dispatch("sync_task_library_list", {
      'jwt': store.getters.get_jwt,
      'organisation_id': store.getters.get_organisation_id_active,
      'library_list': store.getters.get_library_list
    })
  }  
}

export async function reload_libraries() {

  await store.dispatch("reset_libraries")

  // Reload libraries
  await load_libraries()

  // Compute user access
  const user_id = store.getters.getUser['user_id']
  load_library_user_access(user_id)
  init_library_selectors()
  await login_library_module()
}

export async function login_library_module() {

  // System admin does not need to login
  const user = store.getters.getUser
  if (user['role'].includes('SYSTEM_ADMIN')) {
    return
  }

  // Login into library module
  const jwt = store.getters.get_jwt
  const response = await post_libraries_authenticate(jwt)
  const json = await response.json();
  store.commit("set_jwt", json['jwt'])
}

export async function get_edition_isbn13_list(book_edition_id) {
  /*
  Get all isbn13 numbers given an book_edition_id.
  Pull results from cache if stored.
  
  :param int book_edition_id: the book_edition id of the book products.
  */

  if (!(book_edition_id in store.getters.get_edition_isbn13_list_dict)) {
    const jwt = store.getters.get_jwt
    const json = await get_books(jwt, 'products', {
      'select': 'isbn13',
      'book_edition_id': book_edition_id
    })
    const isbn13_list = json['books'].map(x => x['isbn13'])
  
    store.commit("add_edition_isbn13_list", {
      'book_edition_id': book_edition_id,
      'isbn13_list': isbn13_list
    })
  }

  return store.getters.get_edition_isbn13_list_dict[book_edition_id] 
}

export async function pick_book(user_id, isbn13) {

  const jwt = store.getters.getJwt

  // Load book (from cache)
  const book_product = await store.dispatch("load_book", isbn13)

  // Handle library if this book was picked from a library
  const library_id = store.getters.get_active_library_id
  if (library_id != 0) {
      
      // Borrow book copy from library
      const copy_id = await get_available_copy_id(jwt, library_id, isbn13)
      if (copy_id == null) {
        alert("Geen kopie beschikbaar.")
        return 404
      }

      const response = await put_libraries_books_copies_users_borrow(
          jwt,
          library_id,
          isbn13,
          copy_id,
          user_id,
          store.getters.get_organisation_id_active,
          get_datetime_str()
      )
      if (response.status == 401) {
        alert("Geen toegangsrechten tot deze bieb.")
        return 401
      }

      // Reload borrowed books
      await store.dispatch("load_books_borrowed", {
        'jwt': jwt,
        'user_id': user_id
      })
  }

  // Update state and push state to server
  await add_progress(parseInt(user_id), isbn13, 1, book_product.page_count)

  // Sleep for short time
  // Increases chance data that data fully propagates to all back-end services
  // Results in better feedback and UX
  await sleep(50)
}

function is_cached_query(book_edition_id_dict, library_id, book_edition_id) {
  return library_id in book_edition_id_dict && book_edition_id in book_edition_id_dict[library_id]
}

export async function load_author_recommendations() {
  const isbn13 = store.getters.get_isbn13_active

  // Quit if the active isbn13 is unknown
  if (!isbn13) return

  // Quit if query was cached
  const libraryId = store.getters.get_active_library_id      
  const book = store.getters.getBook(isbn13)
  const bookEditionId = book['book_edition_id']
  const authorId = book['author_id']
  // const book_edition_id_dict = store.getters.get_author_tips_book_edition_id_dict
  // if (is_cached_query(book_edition_id_dict, library_id, book_edition_id)) {
  //   return
  // }

  // const author_id = get_author_id_by_edition_id(book_edition_id)

  let body = {
    'jwt': store.getters.get_jwt,
    'book_edition_id': bookEditionId,
    'author_id': authorId,
  }

  if (libraryId != 0) {
    body['library_id'] = libraryId
  }

  await store.dispatch("load_book_edition_id_list_author", body)
}

export async function load_series_recommendations() {
  const isbn13 = store.getters.get_isbn13_active

  // Quit if the active isbn13 is unknown
  if (!isbn13) return

  // Quit if query was cached
  const library_id = store.getters.get_active_library_id
  const book = store.getters.getBook(isbn13)
  const book_edition_id = book.book_edition_id
  const book_edition_id_dict = store.getters.get_series_tips_book_edition_id_dict
  if (is_cached_query(book_edition_id_dict, library_id, book_edition_id)) {
    return
  }

  // Quit if no series or release on reading order
  if (book['series_id'] == null || book['release_number'] != null) {
    return
  }

  let body = {
    'jwt': store.getters.get_jwt,
    'book_edition_id': book_edition_id,
    'series_id': book['series_id'],
  }

  if (library_id != 0) {
    body['library_id'] = library_id
  }
  
  await store.dispatch("load_book_edition_id_list_series", body)
}

export async function load_book_recommendations_author(author_id) {

  // Quit if query was cached
  const library_selector_list = store.getters.get_library_selector_list
  const library_selector = library_selector_list[store.getters.get_active_library_selector]
  const recommendation_dict = store.getters.get_recommendation_author_dict

  if (!author_id) {
    return []
  }

  const query_str = JSON.stringify(library_selector.query)
  if (query_str in recommendation_dict && author_id in recommendation_dict[query_str]) {
    return recommendation_dict[query_str][author_id]
  }

  // Get recommendations
  let query = {
    'select': 'isbn13,book_edition_id,book_title,author_name',
    'author_id': author_id,    
    'limit': 8,
    'page': 0
  }

  if ('library_id' in library_selector.query) {
    query['select'] = 'isbn13,book_edition_id,book_title,author_name,copies_total,copies_in_use'
    query['library_id'] = library_selector.query['library_id']
  }

  const json = await get_books(store.getters.get_jwt, 'editions', query)

  // Build tile list
  let tile_list = []
  json['books'].forEach(book => {
    tile_list.push({
      'isbn13': book['isbn13'],
      'book_edition_id': book['book_edition_id'],
      'book_title': book['book_title'],
      'author_name': book['author_name'],
      'sub_text': book['author_name'],
      'copies_total': 'copies_total' in book ? book['copies_total'] : null,
      'copies_in_use': 'copies_in_use' in book ? book['copies_in_use'] : null
    })
  })
  
  // Store tile_list for this book_edition_id in store
  store.commit('set_recommendation_author', {
    'library_id': query_str,
    'author_id': author_id,
    'tile_list': tile_list
  })

  return tile_list
}

export async function load_book_recommendations_series(series_id) {

  // Quit if query was cached
  const library_selector_list = store.getters.get_library_selector_list
  const library_selector = library_selector_list[store.getters.get_active_library_selector]
  const recommendation_dict = store.getters.get_recommendation_series_dict

  if (!series_id) {
    return []
  }

  const query_str = JSON.stringify(library_selector.query)
  if (query_str in recommendation_dict && series_id in recommendation_dict[query_str]) {
    return recommendation_dict[query_str][series_id]
  }

  // Get recommendations
  let query = {
    'select': 'isbn13,book_edition_id,book_title,author_name',
    'series_id': series_id,    
    'limit': 8,
    'page': 0
  }

  if ('library_id' in library_selector.query) {
    query['select'] = 'isbn13,book_edition_id,book_title,author_name,copies_total,copies_in_use'
    query['library_id'] = library_selector.query['library_id']
  }

  const json = await get_books(store.getters.get_jwt, 'editions', query)

  // Build tile list
  let tile_list = []
  json['books'].forEach(book => {
    tile_list.push({
      'isbn13': book['isbn13'],
      'book_edition_id': book['book_edition_id'],
      'book_title': book['book_title'],
      'author_name': book['author_name'],
      'sub_text': book['author_name'],
      'copies_total': 'copies_total' in book ? book['copies_total'] : null,
      'copies_in_use': 'copies_in_use' in book ? book['copies_in_use'] : null
    })
  })
  
  // Store tile_list for this book_edition_id in store
  store.commit('set_recommendation_series', {
    'library_id': query_str,
    'series_id': series_id,
    'tile_list': tile_list
  })

  return tile_list
}

export function isbn13_borrowed(user_id, isbn13) {
  const active_library_id = store.getters.get_active_library_id
  let books_borrowed = store.getters.get_books_borrowed
  if (active_library_id != 0 && user_id in books_borrowed && active_library_id in books_borrowed[user_id]) {
    let isbn13_list_borrowed = []
    const books_borrowed_list = books_borrowed[user_id][active_library_id]
    books_borrowed_list.forEach(x => isbn13_list_borrowed[x['isbn13']])

    return isbn13_list_borrowed.includes(isbn13.toString())
  }
}

function filter_roles(valid_roles, user_roles) {
  for (let i = 0; i < valid_roles.length; i++) {
    for (let k = 0; k < user_roles.length; k++) {
      if (valid_roles[i] == user_roles[k]) {
        return true
      }
    }
  }

  return false;
}

export function load_library_user_access(user_id) {
  /* Reload library access with an identification token and return the user.

  :param int user_id: the id of the user for whom access should be loaded.
  */

  const library_dict = store.getters.get_library_dict
  const role_list = store.getters.getUsers[user_id]['role']

  // A librarian can edit and see every library within a school
  if (filter_roles(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'SYSTEM_ADMIN'], role_list)) {
    for (let library_id in library_dict) {
      store.commit('add_library_user', {
        'user_id': user_id,
        'library_id': library_id,
      })
    }
  }

  // Other users can only see the libraries that they have access to
  else if (filter_roles(['STUDENT'], role_list)) {

    // Get user access scope
    const user_access = store.getters.get_user_access_dict[user_id]
    if (!user_access) {
      console.warn(`Failed to load library_user_access for user(user_id: ${user_id})`)
      return
    }
    const classroom_id_set = user_access.classroom_id_set

    if (classroom_id_set && classroom_id_set.size > 0) {

      for (let library_id in library_dict) {
        const library = library_dict[library_id]
        const classroom_id_overlap = library['classroom_access'].filter(value => classroom_id_set.has(value.classroom_id) && value.has_access);

        // Skip if user does not have access to this library
        if (classroom_id_overlap.length == 0) {
          continue
        }

        store.commit('add_library_user', {
          'user_id': user_id,
          'library_id': library_id,
        })
      }
    }
  }
}

export function init_library_selectors() {
  // Skip if we refreshed
  const user = store.getters.getUser
  if (!user) {
    return
  }

  // Get library access
  const role_list = user['role']
  const library_dict = store.getters.get_library_dict

  let library_id_list = null
  if (role_list.includes('SYSTEM_ADMIN') || role_list.includes('READING_CONSULTANT') || role_list.includes('BOOKSHOP_OWNER')) {
    library_id_list = Object.keys(library_dict)
  } else if (user['user_id'] in store.getters.get_user_library_dict) {
    library_id_list = Array.from(store.getters.get_user_library_dict[user['user_id']])
  }

  // Create list of available libraries
  let library_selector_list = []
  let library_selector_dict = {}
  if (library_id_list && library_id_list.length > 0) {

    // School - all libraries in school
    library_selector_list.push({
      'name': 'School',
      'query': {'library_id': library_id_list.join(',')},
      'location_name': 'School'
    })

    // Get all libraries for each location
    library_id_list.forEach(library_id => {
      let location_name = library_dict[library_id].location_name
      if (!(location_name in library_selector_dict)) {
        library_selector_dict[location_name] = []
      }
      library_selector_dict[location_name].push(library_id)
    })

    // Created group filter for each location if multiple libraries are present
    let other_list = []
    let location_name_list = Object.keys(library_selector_dict)
    if (location_name_list.length > 1) {
      location_name_list.forEach(location_name => {
        let library_id_list = library_selector_dict[location_name]
        if (library_id_list.length > 1) {
          library_selector_list.push({
            'name': location_name,
            'query': {'library_id': library_id_list.join(',')},
            'location_name': location_name
          })
        }
        else {
          other_list.push(library_selector_dict[location_name])
        }
      })
    }

    // Group libraries with unique locations
    if (other_list.length > 0) {
      let library_id_list_other = []
      other_list.forEach(library_id => library_id_list_other.push(library_id))
      library_selector_list.push({
        'name': 'Overige',
        'query': {'library_id': library_id_list_other.join(',')},
        'location_name': 'Overige'
      })
    }

    // Add all libraries seperately
    library_id_list.sort((a, b) => library_dict[a].library_name.localeCompare(library_dict[b].library_name))
    library_id_list.forEach(library_id => {
      library_selector_list.push({
        'name': library_dict[library_id].library_name,
        'query': {'library_id': library_id.toString()},
        'location_name': library_dict[library_id].location_name,
      })
    })
  }

  // Set indices
  library_selector_list.forEach((value, index) => value['index'] = index)

  // Update store
  store.commit("set_library_selector_list", library_selector_list)
}

export async function load_tag_quick_search() {

  const tag_tile_list = [
    'magie en fantasie',
    'sprookjes',
    'detective',
    'science fiction',
    'dieren',
    'griezelig',
    'sport',
    'oorlog',
    'geschiedenis',
    'aardrijkskunde',
    'kunst en cultuur',
    'avontuur',
    'superhelden',
    'liefde en relaties',
    'feest',
    'wetenschap en techniek',
    'grappig',
    'school',
    'eten en drinken',
    'krijgers',
    'misdaad',
    'geloof en religie',
    'nederland',
    'lichaam en gezondheid'
  ]

  if (store.getters.get_tag_list_quick_search.length > 0) {
    return
  }

  // Query
  const jwt = store.getters.get_jwt
  const json = await get_tags_query(jwt, {
    'tag_name': tag_tile_list.join(','),
    'limit': 500
  })

  // Parse
  let tag_dict = {}
  json['tag_list'].forEach(x => {
    tag_dict[x['tag_name']] = ({
      tag_id: x['tag_id'],
      tag_name: x['tag_name'],
      tag_name_alias: x['tag_name'],
      color: x['color'] != null ? '#' + x['color'] : null
    })
  })

  let tag_list = []
  tag_tile_list.forEach(x => {
    if (x in tag_dict) {
      tag_list.push(tag_dict[x])
    }
  })

  // Store
  store.commit("set_tag_list_quick_search", tag_list)
}

export async function load_tag_highlights() {

  if (store.getters.get_tag_list_highlight.length > 0) {
    return
  }

  const tag_name_dict = {
    'voetbal': 'Hup Holland Hup',
    'sociale media': 'mobieltjes in de klas',
    'klimaatverandering': 'klimaatverandering',
    'vluchteling': 'vluchtelingen en asiel',
    'jodendom': 'oorlog Israël en Gaza',
    'zomer': 'zomer'    
  }

  // Query
  const jwt = store.getters.get_jwt
  const json = await get_tags_query(jwt, {
    'tag_name': Object.keys(tag_name_dict).join(','),
    'limit': 10
  })

  // Parse
  let tag_list = []
  json['tag_list'].forEach(x => {
    tag_list.push({
      tag_id: x['tag_id'],
      tag_name: x['tag_name'],
      tag_name_alias: tag_name_dict[x['tag_name']],
      color:  x['color'] != null ? '#' + x['color'] : null
    })
  })

  // Store
  store.commit("set_tag_list_highlight", tag_list)
}

export function get_can_borrow(user_id_borrow) {

  // Ignore on refresh
  const user = store.getters.getUser
  if(!user) {
    return false
  }
  const user_id_self = user['user_id']

  // Compute can_borrow
  let can_borrow = true
  if (user_id_borrow == user_id_self && has_role(['STUDENT'])) {
    can_borrow = false
    const classroom_settings_dict = store.getters.get_classroom_borrow_independence
    const group_dict = store.getters.get_group_dict
    const group_user_dict = store.getters.get_group_user_dict
    const group_id_list = group_user_dict[user_id_borrow]
    let classroom_id_set = new Set()
    group_id_list.forEach(group_id => classroom_id_set.add(group_dict[group_id].classroom_id))
    for (let classroom_id of classroom_id_set) {
      if (classroom_id in classroom_settings_dict && classroom_settings_dict[classroom_id]) {
        can_borrow = true
        break
      }
    }
  }

  return can_borrow
}
