<template>
  <oib-page style="background-color: var(--primary-color-sub)">
    <oib-nav-student title="Partner zoeken" />
    <oib-content padding_top='padding_top' :show_overlay="false">
      <div v-if="!has_role(['LIBRARIAN', 'SYSTEM_ADMIN'])" class="wrapper row" style="margin-bottom: 20px;">
        <p>Je hebt geen permissies om deze taak uit te voeren. Vraag de biebbeheerder om een partner te koppelen.</p>
      </div>
      <div v-if="has_role(['LIBRARIAN', 'SYSTEM_ADMIN'])">
        <div class="wrapper row" style="margin-bottom: 20px;">
          <oib-search-bar
          placeholder="Zoek op bedrijfsnaam"
          @search="x => run_query(x)"
          :search_frequency_ms="500"
          v-model="search_query"        
          />
        </div>
        <div class="wrapper-from-tablet">
          <div class="row grid" style="flex-wrap: wrap;">
            <oib-list-item-partner
              v-for="partner in organisation_list"
              :key="partner.organisation_id"
              :organisation_id="partner.organisation_id"
              :title="partner.organisation_name"
              :subtext="partner.street_name + ' ' + partner.house_number + ', ' + partner.zipcode + ' ' + partner.city"
              :distance="partner.distance.toFixed(2)"
            />
          </div>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibSearchBar from "../components/OibSearchBar.vue";
import OibListItemPartner from "../components/oib_list_item_partner.vue"

import { has_role } from "../store/utils";
import { get_organisations_scrape_discover } from "../store/api/school_partner.js"

export default {
  name: "OverzichtScholen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibSearchBar,
    OibListItemPartner
  },
  data() {
    return {
      organisation_list: [],
      search_query: ""
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // import
    has_role,

    // local
    async run_query(search_query) {

      // Build query
      let query = {
        'organisation_id': this.$store.getters.get_organisation_id_active,
        'organisation_type': 'BOOKSHOP,LIBRARY',
        'limit': 1000,
        'page': 0
      }
      if (search_query) {
        query['search'] = search_query
      }

      // Run request
      const jwt = this.$store.getters.get_jwt
      const json = await get_organisations_scrape_discover(jwt, query)
      this.organisation_list = json['organisation_list']
    }
  },
  async mounted() {
    if (!this.$store.getters.getUser) {
      return
    }
    this.run_query()    
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.hide {
  display: none;
}



@media screen and (min-width:640px) {
  .wrapper-from-tablet {
    width: 90%;
    margin: auto;
  }

  .grid {
    max-width: 1400px;
  }
}
</style>
