<template>
  <section>
    <div class="wrapper column log-book">
      <div class="button-label-group">
        <oib-button-label
          v-for="(filter, log_category) in log_category_filters"
          :key="log_category"
          :active="filter.active"
          v-on:click="toggle_log_category_filter(log_category)"
          style="margin-right: 10px;"
          >
          {{filter.title}}
        </oib-button-label>
      </div>
      <oib-paginator @on_bottom_reached="on_bottom_reached" v-if="logs">
        <oib-list-item-logbook
          v-for="log in logs"
          :key="log"
          :log="log"
          :user_id="user_id"
          style="max-width: 1342px;"
        />
      </oib-paginator>
    </div>
  </section>
</template>

<script>
import OibButtonLabel from "../components/OibButtonLabel.vue";
import OibListItemLogbook from "../components/oib_list_item_logbook.vue"
import OibPaginator from "../components/oib_paginator.vue"
import {get_logs} from "../store/api/logging.js"

export default {
  name: "LogBook",
  props: ["user_id"],
  components: {
    OibButtonLabel,
    OibListItemLogbook,
    OibPaginator
  },
  data() {
    return {
      page: 0,
      limit: 30,
      displayProgress: true,
      loading: false,
      progressUserFormatted: [],
      logs: [],
      log_category_filters: {
        0: {
          'title': 'Account',
          'active': true
        },
        1: {
          'title': 'Voortgang',
          'active': true
        },
        2: {
          'title': 'Bibliotheek',
          'active': true
        },
        3: {
          'title': 'Vaardigheid',
          'active': true
        }
      },
    };
  },
  methods: {
    toggle_log_category_filter(log_category) {
      this.log_category_filters[log_category]['active'] = !this.log_category_filters[log_category]['active'];
      this.reset_pagination()
      this.load_logs()
    },
    on_bottom_reached() {
      if ((this.page + 1) * this.limit == this.logs.length && !this.loading) {
        this.loading = true
        this.page += 1
        this.load_logs()
      }      
    },
    reset_pagination() {
      this.logs = []
      this.page = 0
      this.limit = 30
    },
    async load_logs() {

      this.loading = true

      // Get active log_categories
      let log_categories_active = []
      for (const [log_category_id, value] of Object.entries(this.log_category_filters))  {
        
        // Filter failed matches
        if (value.active) {
          log_categories_active.push(log_category_id)
        }        
      }

      if (log_categories_active.length == 0) {
        this.logs = []
        return
      }

      // Execute API call
      const jwt = this.$store.getters.get_jwt
      const json = await get_logs(jwt, {
          'user_id': this.user_id,
          'log_category': log_categories_active.join(','),
          'page': this.page,
          'limit': this.limit
      })
      
      // Parse logs
      json['logs'].forEach(log => this.logs.push(log))

      this.loading = false
    }
  },
  async created() {
    if (!this.$store.getters.getUser) {
      return
    }

    // set scroll to top
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    await this.load_logs()
  },
};
</script>

<style scoped>
.button-label-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
</style>