<template>
  <div>
    <library-selector-picker v-show="selector_active == null" @submit="on_selector_picked"/>
    <oib-page v-show="selector_active != null">
      <oib-nav-student title="Bieb herstellen"/>
      <oib-content >
        <oib-menu-item
        description="Type de naam van de bibliotheek waarvan je alle boeken wil inleveren."
        style="padding-top: 20px;"
        >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="restore"
            title="Weet je zeker dat je alle boeken in de bieb wil inleveren?"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <div class="wrapper column">
          <input type="text" :placeholder="selector_active != null ? selector_active.name : ''" v-model="library_name"/>
          <oib-button
            shape="rounded"
            style="margin-top: 50px;"
            :disabled="!is_valid_form"
            @click="submit"
            >Herstellen</oib-button>
        </div>
      </oib-content>
    </oib-page>
  </div>  
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"
import OibButton from "../components/OibButton.vue"
import LibrarySelectorPicker from "../components/library_selector_picker.vue";

import { put_libraries_books_copies_users_return_all } from '../store/api/library.js'
import { get_datetime_str } from '../store/utils'
import { reload_libraries } from "../store/library.js";

export default {
  name: "Missies",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel,
    OibButton,
    LibrarySelectorPicker
  },
  data() {
    return {
      selector_active: null,
      library_name: "",
      error_msg: " ",
      building_access_set: new Set(),
      classroom_access_set: new Set()
    }
  },
  computed: {
    is_valid_form() {
      console.log(this.selector_active != null && this.selector_active.name == this.library_name)
      return this.selector_active != null && this.selector_active.name == this.library_name
    },
  },
  methods: {
    on_selector_picked() {
      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      this.selector_active = selector_list[selector_active_index]
    },
    async submit() {
      const jwt = this.$store.getters.get_jwt
      const library_id_list = this.selector_active.query['library_id'].split(',')

      // Return all rented books in library
      await library_id_list.forEach(async library_id => {
        await put_libraries_books_copies_users_return_all(jwt, library_id, get_datetime_str())
      });

      await reload_libraries()

      // Go back to library management screen
      this.$router.push({
        name: 'BibliotheekCollectie',
        params: {
          user_id: this.$store.getters.getUser['user_id']
        }
      });
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}
</style>