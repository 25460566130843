<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Importeer leestoetsen" @submit="submit">
    <div v-if="has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])">
      <oib-input-file
      label="Niet methode toetsen Excelbestand"
      :required="true"
      :error_msg="error_msg"
      @input="on_file_input"
      >
      <template v-slot:hint>
        <p v-if="lvs_type == 'PARNASSYS'" style="font-size: 0.8em"><a href="https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#hoe_exporteer_ik_leestoetsen_uit_parnassys" target="_blank">* Bekijk de ParnasSys handleiding</a></p>
        <p v-if="lvs_type == 'ESIS'" style="font-size: 0.8em"><a href="https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#hoe_exporteer_ik_leestoetsen_uit_esis" target="_blank">* Bekijk de ESIS handleiding</a></p>
      </template>
      </oib-input-file>
    </div>
    <div v-if="!has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])">
      <p>Je hebt geen permissies om deze taak uit te voeren. Vraag een schoolbeheerder om de leestoetsen te importeren.</p>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputFile from "../components/oib_form/oib_input_file.vue"
import { has_role } from "../store/utils";

export default {
  name: "OibFormLibrary",
  components: {
    OibForm,
    OibInputFile,
  },
  props: ["init_error_msg"],
  data() {
    return {
      form_step_validation_list: [false],
      file: null,
      error_msg: null,
      lvs_type: null
    }
  },
  watch: {
    init_error_msg() {
      this.error_msg = this.init_error_msg
    }
  },
  async created() {

    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    if (!has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])) {
      return
    }

    // If connection already made and loading students, replace route with activate classrooms
    const school = this.$store.getters.get_school
    if (!school) {
      return
    }
    const branch_number = school['brin'] + school['dependance_code']

    await this.$store.dispatch('load_lvs', {
      'jwt': this.$store.getters.get_jwt,
      'branch_number': branch_number
    })

    const sync_request = this.$store.getters.get_lvs_sync_request
    if (sync_request == null) {
      this.error_msg = "Geen koppeling met een leerlingvolgsysteem gevonden"
    } else if (!sync_request['is_succesful']) {
      this.error_msg = `De autorisatie sleutel voor ${sync_request['lvs_type']} is niet meer geldig`
    } else {
      this.lvs_type = sync_request.lvs_type
    }
  },
  mounted() {
    this.val_step_1()
  },
  methods: {
    // Import 
    has_role,

    // Local
    on_file_input(event) {
      this.file = event.target.files[0]
      this.val_step_1()
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.file != null
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'file': this.file,
        'lvs_type': this.lvs_type
      })
    }
  }
};
</script>
