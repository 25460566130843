<template>
  <oib-page>
    <oib-nav-student title="Bieb" :allowLogout="true" :show_back="false">
      <template v-slot:header><slot name="header">
        <oib-header-search
        placeholder="Leerkrachten zoeken"
        @search="query => load_users(query)"
        :search_frequency_ms="300"
        >
          <template v-slot:left>
            <oib-button-icon icon="person_add" @click="go_to_route('MenuLeerkrachtenToevoegen')" style="font-size: 24px" />
          </template>
          <template v-slot:search_collection>
            <oib-button-inline-classroom/>  
          </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>
    <oib-modal ref="modal_sort">
      <div class="wrapper column" style="max-width: 400px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <div class="row" style="width: 100%; align-items: center; justify-content: space-between;">
          <h2 class="h2">Rol toevoegen</h2>
          <oib-button-icon
            icon="help"
            style="margin-left: 10px; margin-top: 5px; font-size: 24px; padding: 0; color: var(--contrast-color-p2)"
            @click="go_to_link('https://leesapp.onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#welke_rol_geef_ik_een_gebruiker')"
            />
        </div>
        <div style="margin-bottom: 0px">
          <div
          v-for="role in Object.keys(role_client_to_server)"
          :key="role"
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 15px 0; cursor: pointer"
          @click="toggle_user_role(role_client_to_server[role])"
          >
            <p
            style="font-size: 0.9em; margin: 0; width: 200px;"
            :style="user_active != null && user_active.role.includes(role_client_to_server[role]) ? 'color: var(--highlight-color)' : 'color: white'"
            >{{role}}</p>
            <oib-button-icon
            v-if="user_active != null && user_active.role.includes(role_client_to_server[role])"
            icon="check"
            style="font-size: 24px; padding: 0; color: var(--highlight-color)"
            />
          </div>
        </div>
      </div>
    </oib-modal>

    <oib-content padding_top='padding_top'>
      <div class="wrapper column" />
      <oib-tab-gebruikers
      v-if="has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'SYSTEM_ADMIN'])"
      :style="$mq == 'desktop' ? 'margin-top: -47px;' : ''"
      tab_active='leerkrachten'
      />

      <!-- Link LVS -->
      <div class="wrapper column" v-if="!lvs_connected" style="margin-bottom: 10px;">
        <div class="row" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap">
          <div style="min-width: 360px">
            <h4 style="font-size: 0.9em; margin: 5px 0; padding-right: 10px;">Klik linksboven op <span class="material-icons" style="font-size: 1em;">person_add</span> om het LVS te koppelen.</h4>
          </div>
        </div>
      </div>
      <!-- No students found -->
      <div class="wrapper column" v-if="lvs_connected && users_loaded && user_list.length == 0">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: center; flex-wrap: wrap; text-align: center; height: 300px; align-items: center;">
          <h4 style="font-size: 0.9em; margin: 5px 0; padding-right: 10px;">Geen leerkrachten gevonden voor "{{ search_str }}"</h4>
          <p style="font-size: 0.7em; width: 100%">Probeer opnieuw te zoeken met een andere spelling of een andere zoekterm.</p>
        </div>
      </div>
      <div class="wrapper column" v-if="lvs_connected && users_loaded && !accounts_added" style="margin-bottom: 10px;">
        <div class="row" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap">
          <div style="min-width: 360px">
            <h4 style="font-size: 0.9em; margin: 5px 0; padding-right: 10px;">Klik op de inlogmethode knop om accounts te koppelen.</h4>
          </div>
        </div>
      </div>

      <div
      v-for="user in user_list"
      :key="user.user_id"
      style="margin-bottom: 10px;"
      >
        <div class="wrapper column">
          <div class="row" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap">
            <div style="min-width: 360px">
              <h4 style="font-size: 0.9em; margin-top: 0; padding-right: 10px; margin-bottom: 5px">{{parse_name(user)}}</h4>
              <p v-if="!user.role.includes('LIBRARY_PARTNER')" style="font-size: 0.7em; margin-top: 0; margin-bottom: 0">{{ parse_role_list(user.role) }}</p>
            </div>

            <div class="row">
              <div
              class="row edit-button"
              :style="user.account_email || user.id_token ? 'background-color: var(--highlight-color)' : 'background-color: var(--negative-color)'"
              @click="go_to_route('InlogmethodeToevoegen', {'user_id': user.user_id})"
              >
                <p style="font-size: 14px; margin-right: 5px;" class="material-icons">edit</p>
                <p style="font-size: 12px;">Inlogmethode</p>
              </div>
  
              <div
              class="row edit-button"
              style="background-color: var(--contrast-color-p4); margin-left: 10px;"
              @click="pick_user_role(user)"
              >
                <p style="font-size: 14px; margin-right: 5px;" class="material-icons">edit</p>
                <p style="font-size: 12px;">Permissies</p>
              </div>
            </div>
            <oib-button-icon
            style="font-size: 24px; color: var(--contrast-color-p4)"
            icon="delete"
            :active="false"
            @click="go_to_route('GebruikerVerwijderen', {'user_id': user.user_id})"
            />
          </div>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibModal from "../components/oib_modal.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibTabGebruikers from "../components/oib_tab_gebruikers.vue"
import OibButtonInlineClassroom from "../components/oib_button_inline_classroom.vue"

import {load_location_selectors} from '../store/user'
import {get_users_query} from "../store/api/user.js"
import {put_users_roles} from "../store/api/user.js"
import {delete_users_roles} from "../store/api/user.js"
import {has_role} from "../store/utils.js"
import { go_to_route } from "../store/navigation";
import { parse_name } from "../store/user.js"

export default {
  name: "OverzichtScholen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibModal,
    OibButtonIcon,
    OibHeaderSearch,
    OibTabGebruikers,
    OibButtonInlineClassroom
  },
  data() {
    return {
      user_list: [],
      role_server_to_client: {
        'TEACHER': 'Leerkracht',
        'LIBRARY_ASSISTENT': 'Biebassistent',
        'LIBRARIAN': 'Biebbeheerder',
        'SUPERVISOR': 'Waarnemer',
        'SCHOOL_ADMIN': 'Schoolbeheerder'
      },
      role_client_to_server: {
        'Leerkracht': 'TEACHER',
        'Biebassistent': 'LIBRARY_ASSISTENT',
        'Biebbeheerder': 'LIBRARIAN',
        'Waarnemer': 'SUPERVISOR',
        'Schoolbeheerder': 'SCHOOL_ADMIN',
      },
      user_active: null,
      query_previous: "",
      search_str: "",
      users_loaded: false
    }
  },
  computed: {
    lvs_connected() {
      const task_dict = this.$store.getters.get_task_dict
      return 'LINK_LVS' in task_dict && task_dict['LINK_LVS'].finish_datetime != null
    },
    accounts_added() {
      const task_dict = this.$store.getters.get_task_dict
      return 'ADD_TEACHER_ACCOUNTS' in task_dict && task_dict['ADD_TEACHER_ACCOUNTS'].finish_datetime != null
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // import
    has_role,
    go_to_route,
    parse_name,

    // local
    parse_role_list(role_list){
      if (!role_list) {
        return ''
      }

      role_list = new Set(role_list)
      let role_list_parsed = []
      for (let role of role_list) {
        role_list_parsed.push(this.role_server_to_client[role])
      }

      return role_list_parsed.join(' • ')
    },
    load_school(school) {
      this.$store.commit("set_organisation_id_active", school.organisation_id)
      this.$store.commit("set_school_id_active", school.school_id)
      this.$router.push({
        name: "Laden"
      });
    },
    remove_user_role(user_id, role) {
      // Remove role client side
      const index = this.user_list.findIndex(x => x['user_id'] == user_id)
      this.user_list[index]['role'] = this.user_list[index]['role'].filter(x => x !== role)

      // Remove role server side
      const jwt = this.$store.getters.get_jwt
      delete_users_roles(jwt, user_id, role)
    },
    toggle_user_role(role) {
      
      // Get client side user
      const user_id = this.user_active.user_id
      const index = this.user_list.findIndex(x => x['user_id'] == user_id)

      // Skip if user already has role
      if (this.user_list[index]['role'].includes(role)) {
        this.remove_user_role(user_id, role)
      } else {
        // Add role client side
        this.user_list[index]['role'].push(role)

        // Remove role server side
        const jwt = this.$store.getters.get_jwt
        put_users_roles(jwt, user_id, role)
      }

      this.toggle_modal()
    },
    go_to_link(href) {
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      setTimeout(() => {
        window.open(href, '_blank');
      })
    },
    async load_users(search_str) {

      const jwt = this.$store.getters.get_jwt
      const organisation_id = this.$store.getters.get_organisation_id_active

      // Build query
      let query = {}
      query['organisation_id'] = organisation_id
      query['select'] = 'user_id,nick_name,insertion_name,last_name,role,account_email,account_type,id_token'
      query['role'] = 'TEACHER,LIBRARY_ASSISTENT,LIBRARIAN,SCHOOL_ADMIN,SUPERVISOR,NONE,SYSTEM_ADMIN,READING_CONSULTANT,BOOKSHOP_OWNER'
      query['active'] = 1
      query['page'] = 0
      query['limit'] = 10000

      this.search_str = search_str
      if (this.search_str != "") {
        query['search'] = this.search_str
      } else {
        query['sort'] = 'nick_name,ASC'
      }

      // Skip execution of duplicate query
      const query_str = JSON.stringify(query)
      if (query_str == this.query_previous) {
        return
      }
      this.query_previous = query_str
      this.users_loaded = false

      // Run query
      const response = await get_users_query(jwt, query)
      const json = await response.json()
      json['user_list'].forEach(user => this.$store.commit("add_user", user))
      this.user_list = json['user_list']
      this.users_loaded = true

      this.$store.dispatch("sync_task_teacher_list", {
        'jwt': jwt,
        'organisation_id': organisation_id,
        'teacher_list': this.user_list
      })
    },
    get_resource_access_scope() {
      return {
        'organisation_id_set': new Set([this.$store.getters.get_organisation_id_active]),
        'building_id_set': new Set(Object.keys(this.$store.getters.get_building_dict)),
        'classroom_id_set': new Set(Object.keys(this.$store.getters.get_classroom_dict))
      }
    },
    pick_user_role(user) {
      this.user_active = user
      this.$refs.modal_sort.toggle()
    },
    toggle_modal() {
      this.$refs.modal_sort.toggle()
    }
  },
  created() {
    load_location_selectors()
  },
  mounted() {
    // Skip if logged out
    if (!this.$store.getters.getUser) {
      return
    }

    this.load_users('')
    
  }
};
</script>

<style scoped>
.hide {
  display: none;
}

.row {
  display: flex;
  flex-direction: row;
}

.customBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35px;
  height: 35px;

  background: white;
  color: #444;
  border-radius: 5px;
  white-space: nowrap;
}
.customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 42px;
}
span.icon img {
  width: 20px;
  height: 20px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

input {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
}

* {  
  box-sizing: border-box;
}

.list-selector {
  display: flex;
  flex-direction: row;
}

.list-selector button {
  margin-right: 10px;
}

.edit-button {
  padding: 2px 5px;
  border-radius: 4px;
  margin: 7px 0;
  align-items: center;
}

.edit-button p {
  color: white;
  font-weight: 600;
  margin: 0;
}

.edit-button:hover{
  cursor: pointer;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
</style>