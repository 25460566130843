import { request_post } from "../request.js"
import { request_put } from "../request.js"
import { request_delete } from "../request.js"
import { request_get } from "../request.js"
import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

export async function post_organisations(jwt, coc_number, coc_business_number, organisation_name, organisation_type, website_url) {
  /* Create an organisation. */

  const request_path = `/organisations`
  const body = {
    'coc_number': coc_number,
    'coc_business_number': coc_business_number,
    'organisation_name': organisation_name,
    'organisation_type': organisation_type,
    'website_url': website_url
  }

  return await request_post(jwt, request_path, body)
}

export async function put_organisations(jwt, organisation_id, coc_number, coc_business_number, organisation_name, organisation_type, website_url, organisation_creation_datetime) {
  /* Create an organisation. */

  const request_path = `/organisations/${organisation_id}`
  const body = {
    'coc_number': coc_number,
    'coc_business_number': coc_business_number,
    'organisation_name': organisation_name,
    'organisation_type': organisation_type,
    'website_url': website_url,
    'organisation_creation_datetime': organisation_creation_datetime
  }

  return await request_put(jwt, request_path, body)
}

export async function post_organisations_schools(jwt, organisation_id, brin, dependance_code, school_type, denomination, student_count, teacher_count) {
  /* Create a school. */

  const request_path = `/organisations/${organisation_id}/schools`
  const body = {
    'brin': brin,
    'dependance_code': dependance_code,
    'school_type': school_type,
    'denomination': denomination,
    'student_count': student_count,
    'teacher_count': teacher_count
  }

  return await request_post(jwt, request_path, body)
}

export async function post_organisations_buildings(jwt, organisation_id, building_name, city, province, zipcode, street_name, house_number, latitude, longitude) {
  /* Add a building to an organisation. */

  const request_path = `/organisations/${organisation_id}/buildings`
  const body = {
    'building_name': building_name,
    'city': city,
    'province': province,
    'zipcode': zipcode,
    'street_name': street_name,
    'house_number': house_number,
    'latitude': latitude,
    'longitude': longitude
  }

  return await request_post(jwt, request_path, body)
}

export async function put_buildings(jwt, building_id, organisation_id, building_name, city, province, zipcode, street_name, house_number, latitude, longitude, building_creation_datetime) {
  /* Add a building to an organisation. */

  const request_path = `/buildings/${building_id}`
  const body = {
    'organisation_id': organisation_id,
    'building_name': building_name,
    'city': city,
    'province': province,
    'zipcode': zipcode,
    'street_name': street_name,
    'house_number': house_number,
    'latitude': latitude,
    'longitude': longitude,
    'building_creation_datetime': building_creation_datetime
  }

  return await request_put(jwt, request_path, body)
}
 
 
export async function post_organisations_users(jwt, organisation_id, role, nick_name, first_names, insertion_name, last_name, birthdate, sex, eck_id) {
  /* Create a school library.

  :param int school_id: the id of the school to which the library should be added.
  :param str library_name: the name of the library that should be created.
  */

  const request_path = `/organisations/${organisation_id}/users`
  const body = {
    'user_role_list': role,
    'nick_name': nick_name,
    'first_names': first_names,
    'insertion_name': insertion_name,
    'last_name': last_name,
    'birthdate': birthdate,
    'sex': sex,
    'eck_id': eck_id
  }

  return await request_post(jwt, request_path, body)
}

export async function post_accounts_internal_authenticate(contact_email, password) {
  /* Create a school library.

  :param int school_id: the id of the school to which the library should be added.
  :param str library_name: the name of the library that should be created.
  */

  const request_path = `/accounts/internal/authenticate`
  const body = {
    'contact_email': contact_email,
    'password_plain': password
  }

  return await request_post(undefined, request_path, body)
}

export async function post_jwt_refresh_resources(jwt) {
  /* Refresh the resources in the jwt. It does not refresh the expiration datetime.

  :param str jwt: a json web token.
  */

  const request_path = `/jwt/refresh/resources`
  return await request_post(jwt, request_path, {})
}

export async function post_schools_partners_authenticate(jwt, school_id) {
  /* Create a school library.

  :param int school_id: the id of the school to which the library should be added.
  :param str library_name: the name of the library that should be created.
  */

  const request_path = `/schools/${school_id}/partners/authenticate`
  return await request_post(jwt, request_path, {})
}

export async function post_schools_partners(jwt, school_id, organisation_id) {
  /* Connect a school to a partner.

  :param int school_id: the school id of the school.
  :param int organisation_id: the organisation id of the partner that should be connected.
  */

  const request_path = `/schools/${school_id}/partners/${organisation_id}`
  return await request_post(jwt, request_path, {})
}

export async function get_users_query(jwt, query) {
  /* Query the users collection.

  :param dict query: the query to execute.
  */

  const query_string = parse_query(query)
  return await request_get(jwt, `/users?${query_string}`, undefined)
}

export async function get_organisations(jwt, organisation_id) {
  return await request_get_json(jwt, `/organisations/${organisation_id}`, undefined)
}

export async function get_schools(jwt, school_id) {
  return await request_get_json(jwt, `/schools/${school_id}`, undefined)
}

export async function get_users(jwt, user_id) {
  return await request_get_json(jwt, `/users/${user_id}`, undefined)
}

export async function get_groups_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/groups?${query_string}`, undefined)
}

export async function get_classrooms_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/classrooms?${query_string}`, undefined)
}

export async function get_buildings_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/buildings?${query_string}`, undefined)
}

export async function get_schools_query(jwt, query) {
    const query_string = parse_query(query)
    return await request_get_json(jwt, `/schools?${query_string}`, undefined)
}

export async function get_organisations_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/organisations?${query_string}`, undefined)
}

export async function get_schools_partners_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/schools/partners?${query_string}`, undefined)
}

export async function put_users_accounts_external(jwt, user_id, account_email, account_type) {
  /* Create or update an external account of a user.

  :param int user_id: the id of the user that owns the account.
  :param str account_email: the email that is found in the token of the external party.
  :param str account_type: GOOGLE | MICROSOFT
  */

  const request_path = `/users/${user_id}/accounts/external/${account_type}`
  const body = {
    'account_email': account_email,
  }

  return await request_put(jwt, request_path, body)
}

export async function delete_users_accounts_external(jwt, user_id, account_type=null) {
  /* Delete the an external account of a user.

  :param int user_id: the id of the user that owns the account.
  :param str account_type: GOOGLE | MICROSOFT
  */

  if (account_type == null) {
    const request_path = `/users/${user_id}/accounts/external`
    return await request_delete(jwt, request_path, undefined)
  } else {
    const request_path = `/users/${user_id}/accounts/external/${account_type}`
    return await request_delete(jwt, request_path, undefined)
  }
}

export async function get_users_accounts_external(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/accounts/external?${query_string}`, undefined)
}

export async function put_users_roles(jwt, user_id, role) {
  /* Add a role to a user.

  :param int user_id: the id of the user that owns the account.
  :param str role: TEACHER | LIBRARIAN | LIBRARY_ASSISTENT | SUPERVISOR | SCHOOL_ADMIN.
  */

  const request_path = `/users/${user_id}/roles/${role}`
  return await request_put(jwt, request_path, undefined)
}


export async function delete_users_roles(jwt, user_id, role) {
  /* Delete a role from a user.

  :param int user_id: the id of the user that owns the account.
  :param str role: TEACHER | LIBRARIAN | LIBRARY_ASSISTENT | SUPERVISOR | SCHOOL_ADMIN.
  */

  const request_path = `/users/${user_id}/roles/${role}`
  return await request_delete(jwt, request_path, undefined)
}

export async function delete_users(jwt, user_id) {
  /* Delete a user.

  :param int user_id: the id of the user that owns the account.
  */

  const request_path = `/users/${user_id}`
  return await request_delete(jwt, request_path, undefined)
}
