<template >
  <div>
    <slot name="header">
      <oib-header
      v-if="show_header"
      class="nav-header"
      :class="$mq"
      :title="title"
      :background="filling"
      :width="headerWidth"
      :show_back="show_back"
      :show_backdrop="show_backdrop"
      >
        <template v-slot:left style="width: 5%" :style="$mq == 'desktop' ? 'min-width: 50px': ''"><slot name="left">
          <oib-button-logout v-if="allowLogout"/>
        </slot></template>
        <template v-slot:center style="width: 100%;"><slot name="center"></slot></template>
        <template v-slot:right style="width: 5%" :style="$mq == 'desktop' ? 'min-width: 50px': ''"><slot name="right"></slot></template>
      </oib-header>
    </slot>
    <nav 
    v-if="$mq == 'desktop'"
    class="nav"
    :class="$mq"
    ref="nav" 
    >
      <div class="nav-content" :class="$mq" v-if="!($mq == 'mobile' && $route.name == 'Scholen')">
        <div class="column" :style="schoolName ? 'border-bottom: 2px solid rgba(255, 255, 255, 0.1)' : ''">
          <img class="icon" src="../assets/logo_oib.svg" style="width: 50px; height: auto; margin-top: 13px; margin-left: 20px;">        
          <div
          v-if="schoolName"
          class="nav-slot fade"
          :class="$mq"
          style="margin-top: 17px;"
          @click="onSchoolSwapClick()"
          >
            <div>
              <oib-button-icon-label
                class="menu-item"
                :class="$mq"
                :active="true"
                icon="swap_horiz"
                :title="schoolName"
                direction="row"
                style="height: 30px;"
                :disabled="!has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT', 'BOOKSHOP_OWNER'])"
              />
            </div>
          </div>
        </div>
        <div class="nav-slot fade" :class="$mq" v-for="link in navLinksApp" :key="link.title" @click="link.on_click(link.name)">
          <div>
            <oib-button-icon-label
              class="menu-item"
              :class="$mq"
              :active="link.name == $route.name"
              :icon="link.icon"
              :title="link.title"
              :direction="direction"
            />
          </div>
        </div>
      </div>
    </nav>

    <!-- SCHOOL_ACCOUNT perspective - mobile -->
    <nav
    v-if="$mq == 'mobile' && !hide_bottom_on_mobile && has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SCHOOL_ADMIN'])"
    class="nav"
    :class="$mq"
    style="height: 60px"
    ref="nav"
    >
    <!-- :class="$mq" v-if="!($mq == 'mobile' && hide_bottom_on_mobile) && navLinksApp.length > 0" -->
      <div class="nav-content" :class="$mq">
        <div class="nav-slot fade" :class="$mq" v-for="link in navLinksApp" :key="link.title" @click="link.on_click(link.name)">
          <div>
            <oib-button-icon-label
              class="menu-item"
              :class="$mq"
              :active="link.name == $route.name"
              :icon="link.icon"
              :title="link.title"
              :direction="direction"
            />
          </div>
        </div>
      </div>
    </nav>

    <!-- EXTERNAL perspective - mobile -->
    <nav
    v-if="$mq == 'mobile' && !hide_bottom_on_mobile && schoolName && has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN'])"
    class="nav"
    :class="$mq"
    style="height: 80px"
    ref="nav"
    >
    <!-- :class="$mq" v-if="!($mq == 'mobile' && hide_bottom_on_mobile) && navLinksApp.length > 0" -->
      <div class="nav-content" :class="$mq">
        <div class="nav-slot fade" :class="$mq" v-for="link in navLinksApp" :key="link.title" @click="link.on_click(link.name)">
          <div>
            <oib-button-icon-label
              class="menu-item"
              :class="$mq"
              :active="link.name == $route.name"
              :icon="link.icon"
              :title="link.title"
              :direction="direction"
            />
          </div>
        </div>
      </div>
      <!-- <div style="height: 1px; width: 100%; background-color: white; z-index: 10;"/> -->
      <div
      class="row"
      style="justify-content: center; align-items: center; background-color: var(--highlight-color);"
      @click="onSchoolSwapClick()">
        <h4 class="h4" style="margin: 0; font-size: 0.8em; margin-right: 5px; padding: 2px;">{{schoolName}}</h4>
        <p style="width: 0; margin: 0; font-size: 1em; color: white" class="material-symbols-outlined">swap_horiz</p>
      </div>
    </nav>
  </div>
</template>

<script>
import OibHeader from "./OibHeader";
import OibButtonIconLabel from "./OibButtonIconLabel";
import OibButtonLogout from "./OibButtonLogout";

import {has_role} from '../store/utils.js'
import { go_to_route } from "../store/navigation";

export default {
  name: "OibNavigationStudent",
  props: {
    title: {
      type: String,
      default: ""
    },
    allowLogout: {
      type: Boolean,
      default: false
    },
    filling: {
      type: String,
      default: "transparent"
    },
    hide_bottom_on_mobile: {
      type: Boolean,
      default: false
    },
    show_back: {
      type: Boolean,
      default: true
    },
    show_header: {
      type: Boolean,
      default: true
    },
    show_backdrop: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OibHeader,
    OibButtonIconLabel,
    OibButtonLogout
  },
  computed: {
    direction() {
      if (this.$mq == "desktop") return "row"
      else if (this.$mq == "mobile") return "column"
      return ""
    },
    schoolName() {
      if (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) &&  this.$store.getters.get_organisation_id == this.$store.getters.get_organisation_id_active) {
        return null
      }
      if (!this.$store.getters.get_organisation) {
        return null
      }
      const organisation_name = this.$store.getters.get_organisation['organisation_name']
      if (this.$mq == 'desktop' && organisation_name.length > 18) {
        return organisation_name.slice(0, 15) + '...'
      } else {
        return organisation_name
      }
    },
    navLinksApp() {
      const user = this.$store.getters.getUser;
      if (!user) return []

      const roles = user['role']
      let menu_items = []

      const school = this.$store.getters.get_school
      // SCHOOL
      if (school) {
        if (this.displayMenuItem(['SCHOOL_ADMIN', 'LIBRARIAN', 'LIBRARY_ASSISTENT', 'TEACHER', 'SUPERVISOR', 'BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], roles)) {
          menu_items.push({
            "icon": "home",
            "title": "Voor jou",
            "name": "Support",
            "on_click": this.go
          })
        }
        if (this.displayMenuItem(['LIBRARIAN', 'SCHOOL_ADMIN', 'SUPERVISOR', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], roles)) {
          menu_items.push({
            "icon": "task_alt",
            "title": "Taken",
            "name": "Taken",
            "on_click": this.go
          })
        }
        if (this.displayMenuItem(['LIBRARIAN', 'LIBRARY_ASSISTENT', 'SUPERVISOR', 'READING_CONSULTANT', 'BOOKSHOP_OWNER', 'SYSTEM_ADMIN'], roles)) {
          menu_items.push({
            "icon": "menu_book",
            "title": "Bibliotheek",
            "name": "BibliotheekCollectie",
            "on_click": this.go
          })
        } else if (this.displayMenuItem(['STUDENT', 'TEACHER'], roles)) {
          menu_items.push({
            "icon": "menu_book",
            "title": "Bibliotheek",
            "name": "BibliotheekLezen",
            "on_click": this.go
          })
        }
        if (this.displayMenuItem(['LIBRARIAN', 'LIBRARY_ASSISTENT', 'TEACHER', 'SUPERVISOR', 'SCHOOL_ADMIN', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], roles)) {
          menu_items.push({
            "icon": "groups",
            "title": "Gebruikers",
            "on_click": this.go_users
          });
        }
        if (this.displayMenuItem(['SCHOOL_ADMIN', 'LIBRARIAN', 'LIBRARY_ASSISTENT', 'TEACHER', 'SUPERVISOR', 'SYSTEM_ADMIN'], roles)) {
          menu_items.push({
            "icon": "shopping_cart",
            "title": "Winkel",
            "name": "Winkel",
            "on_click": this.go
          })
        }
      }

      // BOOKSHOP
      else {
        // menu_items.push({
        //   "icon": "home_work",
        //   "title": "Scholen",
        //   "name": "Scholen",
        //   "on_click": this.go
        // })
        // menu_items.push({
        //   "icon": "analytics",
        //   "title": "Taken",
        //   "name": "Analytics",
        //   "on_click": this.go
        // })
        // menu_items.push({
        //   "icon": "home_work",
        //   "title": "Beheer",
        //   "name": "GebruikersLeerkrachten",
        //   "on_click": this.go
        // })
      }

      return menu_items
    },
    headerWidth() {
      return this.$mq == "desktop" ? 'calc(100% - 200px)' : '100%';
    }
  },
  mounted() {
    this.on_resize()
  },
  watch: {
    $mq() {
      this.on_resize()
    }
  },
  methods: {
    // Import
    has_role,
    go_to_route,

    // Local
    onSchoolSwapClick() {
      if (has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT', 'BOOKSHOP_OWNER', 'READING_CONSULTANT'])) {
        if (this.$route.name == 'Scholen') {
          this.$router.back()
        } else {
          go_to_route('Scholen')
        }
      }      
    },
    on_resize() {
      /* Set fixed position for footer based on initial height of screen.
      This avoids keyboard overlap.
      */

      if (!this.$refs.nav) {
        return
      }

      if (this.$mq == 'mobile') {
        if (this.$store.getters.get_screen_height == null) {
          this.$store.commit("set_screen_height", window.innerHeight)
        }
        this.$refs.nav.style.top = this.$store.getters.get_screen_height - this.$refs.nav.style.height.slice(0, 2) + 'px';
      } else {
        this.$refs.nav.style.top = 0;
      }
    },
    go(name) {
      this.$router.push({
        name: name,
      });
    },
    go_users() {
      if (
          (has_role(['SCHOOL_ADMIN']) && has_role(['LIBRARIAN', 'LIBRARY_ASSISTENT', 'TEACHER']))
          || has_role(['SYSTEM_ADMIN', 'SUPERVISOR'])
        ) {
        const task_dict = this.$store.getters.get_task_dict
        if (
            ('LINK_LVS' in task_dict && task_dict['LINK_LVS'].finish_datetime == null)
            || ('ADD_TEACHER_ACCOUNTS' in task_dict && task_dict['ADD_TEACHER_ACCOUNTS'].finish_datetime == null)
          ) {
          this.go('GebruikersLeerkrachten')
        } else {
          this.go('GebruikersLeerlingen')
        }
      } else if (has_role(['SCHOOL_ADMIN'])) {
        this.go('GebruikersLeerkrachten')
      } else {
        this.go('GebruikersLeerlingen')
      }      
    },
    notYetImplemented() {
      alert("Dit menu is nog niet beschikbaar in de huidige versie.");
    },
    goParamIdUser(name) {
      this.$router.push({
        name: name,
        params: {
          user_id: this.$store.getters.getUser['user_id'],
        },
      });
    },
    displayMenuItem(valid_roles, user_roles) {
      for (let i = 0; i < valid_roles.length; i++) {
        for (let k = 0; k < user_roles.length; k++) {
          if (valid_roles[i] == user_roles[k]) {
            return true
          }
        }
      }

      return false;
    }
  },
};
</script>

<style scoped>
.nav {
  z-index: 3;
  display: flex;
  position: fixed;
  color: var(--contrast-color-p2);
  background-color: var(--primary-color-sub);
  margin: 0;
  text-align: center;
}

.nav.mobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  background: rgb(33,34,47);
  background: linear-gradient(0deg, rgba(33,34,47,1) 0%, rgba(33,34,47,0.9) 100%);
  -webkit-box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.25); 
  box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.25);
}

.nav.desktop {
  width: 200px;
  height: 100%;
  color: white;
  background-color: rgb(27,23,37, 0.5);
  -webkit-box-shadow: 10px 0px 15px -3px rgba(0,0,0,0.25); 
  box-shadow: 10px 0px 15px -3px rgba(0,0,0,0.25);
}

.nav-content {
  display: flex;
  width: inherit;
  height: auto;
}

.nav-content.mobile {
  min-height: 60px;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}

.nav-content.desktop {
  flex-direction: column;
}

.nav-content h1 {
  font-size: 1em;
  width: 100%;
  height: inherit;
  margin: 0;
}

.nav-slot {
  height: inherit;
}

.nav-slot.mobile {
  margin: 0;
  padding: 0 10px;
  height: 40px;
  width: 60px;
  text-align: center;
}

.nav-slot.desktop {
  width: calc(100% - 40px);
  padding: 10px 20px;
}

.nav-slot.desktop:hover {
  background-color: rgba(33, 34, 47, 0.5);
  cursor: pointer;
}

.nav-header.mobile {
  position: fixed;
  top: 0;
  left: 0;
}

.nav-header.desktop {
  margin-left: 200px;
  position: fixed;
  background-color: white;
}

.menu-header {
  margin-top: 40px;
  text-align: left;
}

.menu-item.deskop {
  font-size: 12px;
}

.menu-item.mobile {
  font-size: 10px;
  width: 100%;
}

</style>