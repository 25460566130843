<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Boek toevoegen" @submit="submit">

    <div>
      <oib-form-header header="Verhaal"/>
      <oib-input-multi-select
        label="Boek categorie"
        description="In welke categorie valt dit boek?"
        :allow_multi_select="false"
        :required="true"
        :options="book_type_selector_list"
        :keys_init="get_book_type_selector_index()"
        @select="x => set_book_type(book_type_selector_list[parseInt(x[0])])"
      />
    </div>

    <div>
      <oib-form-header header="Editie"/>
      <oib-input-multi-select
        label="Taal"
        description="In welke taal is deze editie uitgegeven?"
        :allow_multi_select="false"
        :required="true"
        :options="language_selector_list"
        :keys_init="get_language_selector_index()"
        @select="x => set_language(language_selector_list[parseInt(x[0])])"
      />
      <oib-input-multi-select
        label="Dyslexie"
        description="Is deze editie een dyslexie uitgave?"
        :allow_multi_select="false"
        :required="true"
        :options="dyslexia_selector_list"
        :keys_init="get_dyslexia_selector_index()"
        @select="x => set_dyslexia(dyslexia_selector_list[parseInt(x[0])])"
      />
    </div>

    <div>
      <oib-form-header header="Doelgroep"/>
      <oib-input-multi-select
        label="Avi"
        description="Is dit boek geschreven voor een specifiek avi niveau?"
        :allow_multi_select="false"
        :required="true"
        :options="avi_selector_list"
        :keys_init="get_avi_selector_index()"
        @select="x => set_avi(avi_selector_list[parseInt(x[0])])"
      />
      <oib-input-text
      label="Minimale leeftijd"
      placeholder="1"
      :required="false"
      error_msg=""
       :init_value="min_age"
      @input="set_min_age($event.target.value)"
      />
      <oib-input-text
      label="Maximale leeftijd"
      placeholder="12"
      :required="false"
      error_msg=""
       :init_value="max_age"
      @input="set_max_age($event.target.value)"
      />
    </div>

  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"

export default {
  name: "OibFormBookVoteAudience",
  props: ["scrape_data"],
  components: {
    OibForm,
    OibFormHeader,
    OibInputText,
    OibInputMultiSelect,
  },
  data() {
    return {
      form_step_validation_list: [false, false, false],
      book_type: null,
      book_type_selector_list: ['Leesboek', 'Informatief', 'Prentenboek', 'Stripboek', 'Dichtbundel', 'Voorleesboek', 'Oriëntatie op lezen', 'Zoekboek', 'Aanwijsboek'],
      language_selector_list: ['Nederlands', 'Engels', 'Duits', 'Frans', "Spaans", 'Fries'],
      dyslexia_selector_list: ['Ja', 'Nee'],
      avi_selector_list: ['START', 'M3', 'E3', 'M4', 'E4', 'M5', 'E5', 'M6', 'E6', 'M7', 'E7', 'PLUS'],
      binding_method_selector_list: ['Paperback', 'Hardcover'],
      dyslexia: null,
      language: "",
      avi: null,
      min_age: "",
      max_age: "",
      language_dict: {
        0: 'Nederlands',
        1: 'Engels',
        2: 'Duits',
        3: 'Frans',
        4: 'Spaans',
        5: 'Fries'
      },
    }
  },
  async created() {

    const book_type_dict = {
        'LEESBOEK': 0,
        'INFORMATIEF': 1,
        'PRENTENBOEK': 2,
        'STRIPBOEK': 3,
        'DICHTBUNDEL': 4,
        'VOORLEESBOEK': 5,
        'ORIENTATIE_OP_LEZEN': 6,
        'ZOEKBOEK': 7,
        'AANWIJSBOEK': 8
    }

    const language_dict = {
        'DUTCH': 0,
        'ENGLISH': 1,
        'GERMAN': 2,
        'FRENCH': 3,
        'SPANISH': 4,
        'FRYSIAN': 5
    }

    const book_type_int = this.scrape_data['book_type'] ? book_type_dict[this.scrape_data['book_type']] : null
    const language_int = language_dict[this.scrape_data['book_language']]
    this.set_book_type(this.book_type_selector_list[book_type_int]),
    this.set_language(this.language_dict[language_int])
    this.set_dyslexia(this.scrape_data['dyslexia'] ? 'Ja' : 'Nee')
    this.set_avi(this.scrape_data['avi'])
    this.set_min_age(this.scrape_data['min_age'])
    this.set_max_age(this.scrape_data['max_age'])
  },
  methods: {
    in_search_result(var_name, var_value) {
      let book_title_list = []
      for (let i = 0; i < this.search_result.length; i++) {
        book_title_list.push(this.search_result[i][var_name])
      }
      return !book_title_list.includes(var_value)
    },
    get_book_type_selector_index() {
      if (this.book_type == 'Leesboek') {
        return new Set([0])
      } else if (this.book_type == 'Informatief') {
        return new Set([1])
      } else if (this.book_type == 'Prentenboek') {
        return new Set([2])
      } else if (this.book_type == 'Stripboek') {
       return new Set([3])
      } else if (this.book_type == 'Gedicht en rijm') {
        return new Set([4])
      } else if (this.book_type == 'Voorleesboek') {
        return new Set([5])
      }
      return null
    },
    get_language_selector_index() {
      if (this.language == 'Nederlands') {
        return new Set([0])
      } else if (this.language == 'Engels') {
        return new Set([1])
      } else if (this.language == 'Duits') {
        return new Set([2])
      } else if (this.language == 'Frans') {
       return new Set([3])
      } else if (this.language == 'Spaans') {
        return new Set([4])
      } else if (this.language == 'Fries') {
        return new Set([5])
      }
      return null
    },
    get_dyslexia_selector_index() {
      const index = this.dyslexia ? 0 : 1
      return new Set([index])
    },
    get_avi_selector_index() {
      if (this.avi == 'START') {
        return new Set([0])
      } else if (this.avi == 'M3') {
        return new Set([1])
      } else if (this.avi == 'E3 ') {
        return new Set([2])
      } else if (this.avi == 'M4') {
       return new Set([3])
      } else if (this.avi == 'E4') {
        return new Set([4])
      } else if (this.avi == 'M5') {
        return new Set([5])
      } else if (this.avi == 'E5') {
        return new Set([6])
      } else if (this.avi == 'M6') {
        return new Set([7])
      } else if (this.avi == 'M7') {
        return new Set([8])
      } else if (this.avi == 'E7') {
        return new Set([9])
      } else if (this.avi == 'PLUS') {
        return new Set([10])
      }
      
      return null
    },
    set_book_type(value) {
      this.book_type = value
      this.val_step_1()
    },
    set_language(language) {
      this.language = language
      this.val_step_2()
    },
    set_dyslexia(value) {
      this.dyslexia = value == 'Ja'
      this.val_step_2()
    },
    set_avi(avi) {
      this.avi = avi
      this.val_step_3()
    },
    set_min_age(min_age) {
      this.min_age = min_age
      this.val_step_3()
    },
    set_max_age(max_age) {
      this.max_age = max_age
      this.val_step_3()
    },
    val_step_1() {
      this.form_step_validation_list[0] = this.book_type_selector_list.includes(this.book_type)
    },
    val_step_2() {
      this.form_step_validation_list[1] = this.language_selector_list.includes(this.language)
    },
    val_step_3() {
      let valid_min_age = true
      if (this.min_age) {
        valid_min_age = !isNaN(this.min_age) && parseInt(this.min_age) < 18
      }

      let valid_max_age = true
      if (this.max_age) {
        valid_max_age = this.min_age && !isNaN(this.max_age) && parseInt(this.max_age) < 18
      }
      this.form_step_validation_list[2] = valid_min_age && valid_max_age
    },
    async submit() {

      this.$emit("submit", {
        'book_type': parseInt([...this.get_book_type_selector_index()][0]),
        'book_language': [...this.get_language_selector_index()][0],
        'dyslexia': this.dyslexia,
        'avi': this.avi,
        'min_age': this.min_age ? parseInt(this.min_age) : null,
        'max_age': this.max_age ? parseInt(this.max_age) : null,
      })
    }
  }
};
</script>

<style scoped>

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}
</style>